<div
  matRipple
  class="option-container"
  [matRippleColor]="'rgba(255, 255, 255, 0.3)'"
  [class.selected]="selected"
  [ngClass]="{
    'option-container': (isCurrentUserCustodian$ | async) === false,
    'custodian-option-container': (isCurrentUserCustodian$ | async) === true,
  }"
  (click)="onClick($event)"
>
  <mat-checkbox *ngIf="multiple" class="wtax-checkbox mr-2" [ngModel]="selected"></mat-checkbox>
  <div class="option-text text-regular-normal" #optionText>
    <ng-content></ng-content>
  </div>
</div>
