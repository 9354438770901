import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as fromProfile from '@core/store/reducers';
import { select, Store } from '@ngrx/store';
import { isCurrentUserCustodian } from '@shared/helpers/user-role.helper';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { SelectService } from './select.service';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionComponent {
  @Input() public value: any;
  @Input() public viewValue: string;
  @ViewChild('optionText') public textContainer: ElementRef<HTMLDivElement>;

  public readonly isCurrentUserCustodian$ = this.store$.pipe(select(isCurrentUserCustodian));

  public get selected(): boolean {
    return this.selectService.isSelectedOption(this);
  }

  constructor(
    private readonly selectService: SelectService,
    private readonly cdr: ChangeDetectorRef,
    private readonly store$: Store<fromProfile.State>
  ) {}
  public get multiple(): boolean {
    return this.selectService.multiple;
  }

  public getViewValue(): string {
    if (!isEmpty(this.viewValue)) {
      return this.viewValue;
    }
    if (isNil(this.textContainer.nativeElement)) {
      return '';
    }
    return this.textContainer.nativeElement.textContent;
  }

  public onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.selectService.selectOption(this);
  }

  /**
   * Force run change detection
   * Useful for example when current option was filtered out while being selected
   * and then appeared back, after select component marks it as selected it should be reflected in a view
   */
  public refresh(): void {
    setTimeout(() => this.cdr.markForCheck());
  }
}
