import { HttpRequest, HttpResponse } from '@angular/common/http';

export class DocumentsMockService {
  public signDocument(_: HttpRequest<any>, __: string): HttpResponse<void> {
    return new HttpResponse({ status: 200 });
  }

  public markDocumentAsPrinted(_: HttpRequest<any>, __: string): HttpResponse<void> {
    return new HttpResponse({ status: 200 });
  }

  public uploadDocument(_: HttpRequest<any>, __: string): HttpResponse<void> {
    return new HttpResponse({ status: 200 });
  }
}
