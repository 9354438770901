import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import {
  BeneficialOwnerHealthCheckReport,
  BeneficialOwnerHealthCheckReportResult,
  TaxRateCountry,
  TaxRateInvestorType,
  TaxRateTableHealthCheckReport,
  TaxRateTableHealthCheckReportResult,
  TaxReclaimAccrualReport,
  TaxReclaimAccrualReportResult,
} from '@wtax/data-angular';
import { randomElement } from '../functions';

const COUNTRY = ['Australia', 'Germany', 'South Africa'];
const INVESTOR_TYPE = ['NPO', 'Investment Fund', 'Pension Fund'];
const FILE_NAME = ['Request A', 'Request B', 'Request C'];
const CREATED_DATE = ['2019-01-21', '2022-12-14', '2023-03-27'];
export class TaxRatesMockService {
  private readonly taxRateCountries: TaxRateCountry[] = [];
  private readonly taxRateBeneficialOwnerTypesAu: TaxRateInvestorType[] = [];
  private readonly taxRateBeneficialOwnerTypesDe: TaxRateInvestorType[] = [];
  private readonly taxRateBeneficialOwnerTypesZa: TaxRateInvestorType[] = [];
  private readonly beneficialOwnerHealthCheckReport: BeneficialOwnerHealthCheckReport = null;
  private readonly taxRateTableHealthCheckReport: TaxRateTableHealthCheckReport = null;
  private readonly taxReclaimAccrualReport: TaxReclaimAccrualReport = null;

  constructor() {
    const australia: TaxRateCountry = {
      name: 'Australia',
      code: 'au',
      risk_report_request_url: '/tax-rates/countries/au/risk-report-requests',
      tax_rate_investor_types_url: '/tax-rates/countries/au/investor-types',
    };
    this.taxRateCountries.push(australia);
    const germany: TaxRateCountry = {
      name: 'Germany',
      code: 'de',
      risk_report_request_url: '/tax-rates/countries/de/risk-report-requests',
      tax_rate_investor_types_url: '/tax-rates/countries/de/investor-types',
    };
    this.taxRateCountries.push(germany);
    const southAfrica: TaxRateCountry = {
      name: 'South Africa',
      code: 'za',
      risk_report_request_url: '/tax-rates/countries/za/risk-report-requests',
      tax_rate_investor_types_url: '/tax-rates/countries/za/investor-types',
    };
    this.taxRateCountries.push(southAfrica);

    const corporation: TaxRateInvestorType = {
      name: 'Corporation',
      code: 'corporation',
      risk_report_request_url: '/tax-rates/countries/au/risk-report-requests',
    };
    this.taxRateBeneficialOwnerTypesAu.push(corporation);
    const individual: TaxRateInvestorType = {
      name: 'Individual',
      code: 'individual',
      risk_report_request_url: '/tax-rates/countries/au/risk-report-requests',
    };
    this.taxRateBeneficialOwnerTypesAu.push(individual);
    const internationalOrganisation: TaxRateInvestorType = {
      name: 'International Organisation',
      code: 'internationalOrganisation',
      risk_report_request_url: '/tax-rates/countries/au/risk-report-requests',
    };
    this.taxRateBeneficialOwnerTypesAu.push(internationalOrganisation);

    const pensionFund: TaxRateInvestorType = {
      name: 'Pension Fund',
      code: 'pensionFund',
      risk_report_request_url: '/tax-rates/countries/de/risk-report-requests',
    };
    this.taxRateBeneficialOwnerTypesDe.push(pensionFund);
    const investmentFund: TaxRateInvestorType = {
      name: 'Investment Fund',
      code: 'investmentFund',
      risk_report_request_url: '/tax-rates/countries/de/risk-report-requests',
    };
    this.taxRateBeneficialOwnerTypesDe.push(investmentFund);

    const npo: TaxRateInvestorType = {
      name: 'Non-profit Organization',
      code: 'npo',
      risk_report_request_url: '/tax-rates/countries/za/risk-report-requests',
    };
    this.taxRateBeneficialOwnerTypesZa.push(npo);

    const taxReclaimAccrualReportResults: TaxReclaimAccrualReportResult[] = [];
    for (let index = 0; index < 5; index++) {
      const fileName = randomElement(FILE_NAME);
      const createdDate = randomElement(CREATED_DATE);
      const downloadUrl = index % 2 === 0 ? `https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf` : null;

      const result: TaxReclaimAccrualReportResult = {
        file_name: fileName,
        created_date: createdDate,
        download_url: downloadUrl,
      };

      taxReclaimAccrualReportResults.push(result);
    }
    this.taxReclaimAccrualReport = {
      results: taxReclaimAccrualReportResults,
    };

    const taxRateTableHealthCheckReportResults: TaxRateTableHealthCheckReportResult[] = [];
    for (let index = 0; index < 5; index++) {
      const fileName = randomElement(FILE_NAME);
      const countryOfResidence = randomElement(COUNTRY);
      const createdDate = randomElement(CREATED_DATE);
      const downloadUrl = index % 2 === 0 ? `https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf` : null;

      const result: TaxRateTableHealthCheckReportResult = {
        file_name: fileName,
        country_of_residence: countryOfResidence,
        created_date: createdDate,
        download_url: downloadUrl,
      };

      taxRateTableHealthCheckReportResults.push(result);
    }
    this.taxRateTableHealthCheckReport = {
      results: taxRateTableHealthCheckReportResults,
    };

    const beneficialOwnerHealthCheckReportResults: BeneficialOwnerHealthCheckReportResult[] = [];
    for (let index = 0; index < 5; index++) {
      const fileName = randomElement(FILE_NAME);
      const countryOfResidence = randomElement(COUNTRY);
      const investorType = randomElement(INVESTOR_TYPE);
      const createdDate = randomElement(CREATED_DATE);
      const downloadUrl = index % 2 === 0 ? `https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf` : null;

      const result: BeneficialOwnerHealthCheckReportResult = {
        file_name: fileName,
        country_of_residence: countryOfResidence,
        investor_type: investorType,
        created_date: createdDate,
        download_url: downloadUrl,
      };

      beneficialOwnerHealthCheckReportResults.push(result);
    }
    this.beneficialOwnerHealthCheckReport = {
      results: beneficialOwnerHealthCheckReportResults,
    };
  }

  public getTaxRateCountries(): HttpResponse<TaxRateCountry[]> {
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.taxRateCountries,
    });
  }

  public getTaxRateBeneficialOwnerTypesAu(): HttpResponse<TaxRateInvestorType[]> {
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.taxRateBeneficialOwnerTypesAu,
    });
  }

  public getTaxRateBeneficialOwnerTypesDe(): HttpResponse<TaxRateInvestorType[]> {
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.taxRateBeneficialOwnerTypesDe,
    });
  }

  public getTaxRateBeneficialOwnerTypesZa(): HttpResponse<TaxRateInvestorType[]> {
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.taxRateBeneficialOwnerTypesZa,
    });
  }

  public submitBeneficialOwnerHealthCheckRequest(_: HttpRequest<any>): HttpResponse<any> {
    return new HttpResponse({ status: 200 });
  }

  public getBeneficialOwnerHealthCheckReports(request: HttpRequest<any>): HttpResponse<any> {
    const completedParam: boolean | null = request.params.has('completed') ? request.params.get('completed') === 'true' : null;
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body:
        completedParam != null
          ? {
              ...this.beneficialOwnerHealthCheckReport,
              results: this.beneficialOwnerHealthCheckReport.results.filter(
                (beneficialOwnerHealthCheckReport) => (beneficialOwnerHealthCheckReport.download_url != null) === completedParam
              ),
            }
          : this.beneficialOwnerHealthCheckReport,
    });
  }

  public submitTaxRateTableHealthCheckRequest(_: HttpRequest<any>): HttpResponse<any> {
    return new HttpResponse({ status: 200 });
  }

  public getTaxRateTableHealthCheckReports(request: HttpRequest<any>): HttpResponse<any> {
    const completedParam: boolean | null = request.params.has('completed') ? request.params.get('completed') === 'true' : null;
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body:
        completedParam != null
          ? {
              ...this.taxRateTableHealthCheckReport,
              results: this.taxRateTableHealthCheckReport.results.filter(
                (taxRateTableHealthCheckReport) => (taxRateTableHealthCheckReport.download_url != null) === completedParam
              ),
            }
          : this.taxRateTableHealthCheckReport,
    });
  }

  public getTaxReclaimAccrualRequestTemplate(_: HttpRequest<any>): HttpResponse<any> {
    const str =
      'Beneficial Owner Reference Number,Beneficial Owner Name,Beneficial Owner ID,' +
      'Form NR7-R,Form NR301,Form NR302,Form NR303,Form NR304,Form 5000 & Form 5001,' +
      'French,Annex II,Attestation - Article 131 - Sexies I,Form V2A,Form V2B,Form V2C,' +
      'Form V3,Form IC6,Dividends Withholding Tax Refund Claim Form - REIT,' +
      'Dividends Withholding Tax Refund Claim Form,Modelo 210';
    const blob = new Blob([str], { type: 'application/vnd.ms-excel' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = 'request-template.xls';
    downloadLink.click();
    return new HttpResponse({
      status: 200,
    });
  }

  public getTaxReclaimAccrualReports(request: HttpRequest<any>): HttpResponse<any> {
    const completedParam: boolean | null = request.params.has('completed') ? request.params.get('completed') === 'true' : null;
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body:
        completedParam != null
          ? {
              ...this.taxReclaimAccrualReport,
              results: this.taxReclaimAccrualReport.results.filter(
                (taxReclaimAccrualReport) => (taxReclaimAccrualReport.download_url != null) === completedParam
              ),
            }
          : this.taxReclaimAccrualReport,
    });
  }

  public submitTaxReclaimAccrualReportRequest(_: HttpRequest<any>): HttpResponse<any> {
    return new HttpResponse({ status: 200 });
  }
}
