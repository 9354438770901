import { HttpRequest, HttpResponse } from '@angular/common/http';
import {
  CreateMessageRequest,
  CreateMessageThreadRequest,
  Message,
  MessageContact,
  MessageThread,
  MessageThreadStatus,
  UserProfile,
} from '@wtax/data-angular';
import { parseToken, randomBool, randomElement, randomEnumValue, randomId } from '../functions';
import { AttachmentsMockService } from './attachments-mock.service';
import { ClientManagementMockService } from './client-management-mock.service';
import { ProfileMockService } from './profile-mock.service';

const MESSAGE_THREADS_COUNT = Math.floor(Math.random() * 10);
const CONTACTS_COUNT = Math.floor(Math.random() * 10);
const MESSAGES_COUNT = Math.floor(Math.random() * 20) + 1;
const PARTICIPANTS_COUNT = Math.min(CONTACTS_COUNT, Math.floor(Math.random() * 3));

const FIRST_NAMES = [
  'Liam',
  'Noah',
  'Oliver',
  'William',
  'Elijah',
  'James',
  'Benjamin',
  'Lucas',
  'Mason',
  'Ethan',
  'Alexander',
  'Henry',
  'Jacob',
  'Michael',
  'Daniel',
  'Logan',
  'Jackson',
  'Sebastian',
  'Jack',
  'Aiden',
  'Olivia',
  'Emma',
  'Ava',
  'Sophia',
  'Isabella',
  'Charlotte',
  'Amelia',
  'Mia',
  'Harper',
  'Evelyn',
  'Abigail',
  'Emily',
  'Ella',
  'Elizabeth',
  'Camila',
  'Luna',
  'Sofia',
  'Avery',
  'Mila',
  'Aria',
];
const LAST_NAMES = [
  'Smith',
  'Johnson',
  'Williams',
  'Brown',
  'Jones',
  'Garcia',
  'Miller',
  'Davis',
  'Rodriguez',
  'Martinez',
  'Hernandez',
  'Lopez',
  'Gonzalez',
  'Wilson',
  'Anderson',
  'Thomas',
  'Taylor',
  'Moore',
  'Jackson',
  'Martin',
];

const ROLES = ['Sales representative', 'IT guy', 'Developer', 'Administrator', 'Manager', 'Dovakin'];

export class MessagesMockService {
  private readonly messageThreads: Map<string, MessageThread[]> = new Map();
  private readonly messagesMap: Map<string, Message[]> = new Map();
  private readonly messageContacts: MessageContact[] = [];

  constructor(
    private readonly profileMockService: ProfileMockService,
    private readonly attachmentsMockService: AttachmentsMockService,
    private readonly clientManagementMockService: ClientManagementMockService
  ) {
    for (let i = 0; i < CONTACTS_COUNT; i++) {
      this.messageContacts.push(this.createNewMessageContact());
    }
    for (const token of ['WM', 'FM', 'BO', 'INVESTOR']) {
      const currentUser = this.profileMockService.getCurrentUserProfile(token);
      this.messageThreads.set(token, []);

      for (let i = 0; i < MESSAGE_THREADS_COUNT; i++) {
        const participantsNumber = Math.floor(Math.random() * PARTICIPANTS_COUNT);
        const participants: MessageContact[] = new Array(participantsNumber)
          .fill(null)
          .map((_, index) => this.messageContacts[index])
          .concat(currentUser.account_manager)
          .filter((v) => !!v);
        const messages = [];
        for (let j = 0; j < MESSAGES_COUNT; j++) {
          const message = this.createNewMessage(currentUser, participants);
          messages.push(message);
        }
        const messageThread = this.createMessageNewThread(token, participants, messages);
        const messageThreads = this.messageThreads.get(token);
        this.messageThreads.set(token, messageThreads.concat(messageThread));
        this.messagesMap.set(messageThread.id, messages);
      }
    }
  }

  private createNewMessageContact(): MessageContact {
    return {
      id: randomId(),
      name: { first_name: randomElement(FIRST_NAMES), last_name: randomElement(LAST_NAMES) },
      role: randomElement(ROLES),
    };
  }

  private createNewMessage(currentUser: UserProfile, participants: MessageContact[]): Message {
    const contacts = participants.concat({ id: currentUser.id, name: currentUser.name, role: currentUser.role });
    return {
      id: randomId(),
      body: `Message`,
      message_date: new Date().toISOString(),
      attachments: this.attachmentsMockService.createAndStoreRandomAttachments(),
      sender: randomElement(contacts),
    };
  }

  private createMessageNewThread(token: string, participants: MessageContact[], messages: Message[]): MessageThread {
    return {
      id: randomId(),
      status: randomEnumValue(MessageThreadStatus),
      participants,
      last_message_extract: messages[messages.length - 1]?.body?.length > 0 ? messages[messages.length - 1].body.slice(0, 30) : '',
      unread_messages_count: Math.floor(Math.random() * messages.length),
      last_message_date: messages[messages.length - 1]?.message_date,
      has_attachment: messages.some((message) => message.attachments.length > 0),
      client: randomBool() ? this.clientManagementMockService.getRandomSavedClientById(token) : undefined,
    };
  }

  private mapCreateMessageToMessage(createMessage: CreateMessageRequest, currentUser: UserProfile): Message {
    return {
      id: randomId(),
      message_date: new Date().toISOString(),
      sender: { id: currentUser.id, name: currentUser.name, role: currentUser.role },
      body: createMessage.body,
      attachments: this.attachmentsMockService.getStoredAttachmentsByIds(createMessage.attachment_ids || []),
    };
  }

  public getMessageThreads(request: HttpRequest<any>): HttpResponse<MessageThread[]> {
    const token = parseToken(request);

    const messageThreads = this.messageThreads.get(token);

    if (!messageThreads) {
      return new HttpResponse({ status: 404 });
    }

    return new HttpResponse({ status: 200, body: messageThreads });
  }

  public createMessageThread(request: HttpRequest<CreateMessageThreadRequest>): HttpResponse<MessageThread> {
    const token = parseToken(request);
    const currentUser = this.profileMockService.getCurrentUserProfile(token);
    const messageThreads = this.messageThreads.get(token) || [];

    const newMessage = this.mapCreateMessageToMessage(request.body.message, currentUser);
    const client = this.clientManagementMockService.getSavedClientById(token, request.body.client_id);

    const messageThread: MessageThread = {
      id: randomId(),
      status: MessageThreadStatus.OPEN,
      unread_messages_count: 0,
      last_message_extract: newMessage.body?.length > 0 ? newMessage.body.slice(0, 30) : '',
      participants: request.body.participants,
      has_attachment: request.body.message.attachment_ids?.length > 0,
      last_message_date: newMessage.message_date,
      client,
    };

    // reverse for the last message to be first one since the dates are not relevant right now
    this.messageThreads.set(token, messageThreads.concat(messageThread).reverse());
    this.messagesMap.set(messageThread.id, [newMessage]);

    return new HttpResponse({ status: 200, body: messageThread });
  }

  public getLastMessageThread(request: HttpRequest<any>): HttpResponse<MessageThread> {
    const token = parseToken(request);

    const messageThreads = this.messageThreads.get(token);

    if (!messageThreads) {
      return new HttpResponse({ status: 404 });
    }

    const lastMessageThread = messageThreads[messageThreads.length - 1];

    if (!lastMessageThread) {
      return new HttpResponse({ status: 404 });
    }

    return new HttpResponse({ status: 200, body: lastMessageThread });
  }

  public getMessageThread(request: HttpRequest<any>, id: string): HttpResponse<MessageThread> {
    const token = parseToken(request);

    const messageThreads = this.messageThreads.get(token);

    if (!messageThreads) {
      return new HttpResponse({ status: 404 });
    }

    const messageThread = messageThreads.find((thread) => thread.id === id);

    if (!messageThread) {
      return new HttpResponse({ status: 404 });
    }

    return new HttpResponse({ status: 200, body: messageThread });
  }

  public sendMessage(request: HttpRequest<CreateMessageRequest>, id: string): HttpResponse<MessageThread> {
    const token = parseToken(request);
    const currentUser = this.profileMockService.getCurrentUserProfile(token);

    const messageThreads = this.messageThreads.get(token);

    if (!messageThreads) {
      return new HttpResponse({ status: 404 });
    }

    const messageThread = messageThreads.find((thread) => thread.id === id);

    if (!messageThread) {
      return new HttpResponse({ status: 404 });
    }

    const messages = this.messagesMap.get(id) || [];

    const newMessage: Message = this.mapCreateMessageToMessage(request.body, currentUser);

    messageThread.last_message_date = newMessage.message_date;
    messageThread.last_message_extract = newMessage.body?.length > 0 ? newMessage.body.slice(0, 30) : '';
    messageThread.has_attachment = messageThread.has_attachment || newMessage.attachments.length > 0;

    this.messagesMap.set(id, messages.concat(newMessage));

    return new HttpResponse({ status: 200, body: messageThread });
  }

  public readMessageThread(request: HttpRequest<any>, id: string): HttpResponse<MessageThread> {
    const token = parseToken(request);

    const messageThreads = this.messageThreads.get(token);

    if (!messageThreads) {
      return new HttpResponse({ status: 404 });
    }

    const messageThread = messageThreads.find((thread) => thread.id === id);

    if (!messageThread) {
      return new HttpResponse({ status: 404 });
    }

    messageThread.unread_messages_count = 0;

    return new HttpResponse({ status: 200, body: messageThread });
  }

  public getMessages(request: HttpRequest<any>, id: string): HttpResponse<Message[]> {
    const token = parseToken(request);

    const messageThreads = this.messageThreads.get(token);

    if (!messageThreads) {
      return new HttpResponse({ status: 404 });
    }

    const messageThread = messageThreads.find((thread) => thread.id === id);

    if (!messageThread) {
      return new HttpResponse({ status: 404 });
    }

    const messages = this.messagesMap.get(id) || [];

    return new HttpResponse({ status: 200, body: messages });
  }

  public addParticipant(request: HttpRequest<MessageContact>, id: string): HttpResponse<MessageThread> {
    const token = parseToken(request);

    const messageThreads = this.messageThreads.get(token);

    if (!messageThreads) {
      return new HttpResponse({ status: 404 });
    }

    const messageThread = messageThreads.find((thread) => thread.id === id);

    if (!messageThread) {
      return new HttpResponse({ status: 404 });
    }

    messageThread.participants.push(request.body);

    return new HttpResponse({ status: 200, body: messageThread });
  }

  public getMessageContacts(_: HttpRequest<any>): HttpResponse<MessageContact[]> {
    return new HttpResponse({ status: 200, body: this.messageContacts });
  }
}
