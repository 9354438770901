import { Injectable } from '@angular/core';
import { ActivationEnd, ChildActivationEnd, Router } from '@angular/router';
import { EmbeddedMessage } from '@shared/interfaces/embedded-message.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmbeddedStatusMessageService {
  private readonly message$ = new BehaviorSubject<EmbeddedMessage | undefined>(undefined);

  constructor(private readonly router: Router) {
    this.router.events.pipe(filter((event) => event instanceof ActivationEnd || event instanceof ChildActivationEnd)).subscribe(() => {
      this.closeMessage();
    });
  }

  public getMessage$(): Observable<EmbeddedMessage | undefined> {
    return this.message$.asObservable();
  }

  public showMessage(message: EmbeddedMessage): void {
    this.message$.next(message);
  }

  public closeMessage(): void {
    this.message$.next(undefined);
  }
}
