import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as fromProfile from '@core/store/reducers';
import { select, Store } from '@ngrx/store';
import { isCurrentUserPrivateWealth } from '@shared/helpers/user-role.helper';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {
  @Input() public diameter = 50;
  public readonly strokeWidth = 4;

  public get radius(): number {
    return this.diameter / 2;
  }

  public readonly isCurrentUserPrivateWealth$ = this.store$.pipe(select(isCurrentUserPrivateWealth));

  constructor(private readonly store$: Store<fromProfile.State>) {}

  public get innerRadius(): number {
    return this.radius - this.strokeWidth;
  }

  public get dashArray(): number {
    return Math.PI * 2 * this.innerRadius;
  }

  public get dashOffset(): number {
    return this.dashArray * 0.75;
  }
}
