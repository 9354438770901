<div class="container">
  <div *ngIf="showUi">
    <h1 class="h1">Authentication Callback</h1>
    <p class="text-regular-normal"><strong>Session ID:</strong> {{ sessionId }}</p>
    <p class="text-regular-normal"><strong>Source:</strong> {{ source }}</p>
    <p *ngIf="redirectUrl" class="text-regular-normal"><strong>Redirect URL:</strong> {{ redirectUrl }}</p>
    <button matRipple class="button" (click)="continue()">continue</button>
    <button class="button button-secondary" (click)="redirectToLocalhost()">redirect to {{ localHost }}</button>
  </div>
</div>
