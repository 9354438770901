import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Config, CONFIG_TOKEN } from 'wtax-config';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(CONFIG_TOKEN) private readonly config: Config) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const method = request.method;
    const path = request.url.substring(this.config.apiBaseUrl.length);
    const timeoutConfig = this.config.httpTimeouts?.find(
      (timeoutSetting) => new RegExp(timeoutSetting.urlPattern).test(path) && timeoutSetting.method === method
    );

    if (timeoutConfig) {
      return next.handle(request).pipe(timeout(timeoutConfig.timeoutMs));
    }
    return next.handle(request);
  }
}
