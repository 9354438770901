import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Config, CONFIG_TOKEN } from './wtax-config';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', async (): Promise<void> => {
  const response = await fetch('assets/config/config.json');
  const config: Config = await response.json();

  platformBrowserDynamic([
    {
      provide: CONFIG_TOKEN,
      useValue: config,
    },
  ])
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
});
