/**
 * WTax API
 * OpenAPI documentation to describe API endpoints used for the WTax Wealth Management Platform
 *
 * The version of the OpenAPI document: 0.0.0
 * Contact: info@wtax.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export enum QuestionLevel {
  CLIENT = 'CLIENT',
  DIVIDENDROLLEDUP = 'DIVIDEND_ROLLED_UP',
  YEARSPECIFIC = 'YEAR_SPECIFIC',
  QUESTION = 'QUESTION',
}
