import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuestionLevel } from '@modules/questions/enums/question-level.enum';
import { Question, QuestionsClientsMetadata, QuestionsMetadata, QuestionStatus, QuestionType } from '@wtax/data-angular';

const clamp = (number: number, lower: number, upper: number): number => Math.max(lower, Math.min(upper, number));

const QUESTION_MOCKS = [
  'Did the BO engage in a trade or business through a permanent establishment in any of their respective investment countries?',
  'Was the Beneficial Owner part of a partnership, which was formed under the law of any of their respective investment countries?',
  'Please provide the address of the Internal Revenue Service Centre where the tax return is filed.',
];
const MIN_QUESTIONS = 1;
const MAX_QUESTIONS = 4;

const questionsMetadata: QuestionsMetadata = {
  total_count: 3,
  reviewed_dividend_event_report: false,
  accepted_disclaimer: false,
};

@Injectable({
  providedIn: 'root',
})
export class QuestionsMockService {
  constructor() {}

  private getRandomQuestion(): Question {
    let types = Object.values(QuestionType);
    types = types.filter((element) => element !== QuestionType.YESNO_TEXTBOX);
    const type = types[Math.round(Math.random() * (types.length - 1))];
    let levels = Object.values(QuestionLevel);
    levels = levels.filter((element) => element !== QuestionLevel.YEARSPECIFIC);
    const level = levels[Math.round(Math.random() * (levels.length - 1))];
    const text = QUESTION_MOCKS[Math.round(Math.random() * (QUESTION_MOCKS.length - 1))];
    const optional = !!Math.round(Math.random());
    return {
      question_level: level,
      type,
      text,
      question_group_id: '123',
      yesno_answer: undefined,
      text_answer: undefined,
      error_message: undefined,
      clients: [],
      optional,
    };
  }

  public getQuestions(_request: HttpRequest<any>): HttpResponse<Array<Question>> {
    const numberOfQuestions = clamp(Math.round(Math.random() * MAX_QUESTIONS), MIN_QUESTIONS, MAX_QUESTIONS);
    return new HttpResponse({
      body: new Array(numberOfQuestions).fill(null).map(() => this.getRandomQuestion()),
    });
  }

  public getQuestionsMetadata(_request: HttpRequest<any>): HttpResponse<QuestionsMetadata> {
    return new HttpResponse({
      body: { ...questionsMetadata },
    });
  }

  public patchQuestionsMetadata(request: HttpRequest<any>): HttpResponse<QuestionsMetadata> {
    const requestBody = request.body;
    questionsMetadata.reviewed_dividend_event_report =
      requestBody.reviewed_dividend_event_report || questionsMetadata.reviewed_dividend_event_report;
    questionsMetadata.accepted_disclaimer = requestBody.accepted_disclaimer || questionsMetadata.accepted_disclaimer;
    return new HttpResponse({
      body: { ...questionsMetadata },
    });
  }

  public getQuestionsClientsMetadata(_request: HttpRequest<any>): HttpResponse<QuestionsClientsMetadata> {
    return new HttpResponse({
      body: {
        total_count: 64,
        status_counts: [
          { status: QuestionStatus.NOT_STARTED, count: 8 },
          { status: QuestionStatus.IN_PROGRESS, count: 54 },
          { status: QuestionStatus.COMPLETED, count: 2 },
        ],
      },
    });
  }

  public getDividendEventReport(_request: HttpRequest<any>): HttpResponse<any> {
    questionsMetadata.downloaded_dividend_event_report = true;

    return new HttpResponse({
      headers: new HttpHeaders({
        Location: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      }),
    });
  }

  public submitAnswers(_request: HttpRequest<any>): HttpResponse<any> {
    return new HttpResponse({
      status: 200,
    });
  }

  public setAnswers(_request: HttpRequest<any>): HttpResponse<any> {
    return new HttpResponse({
      status: 200,
      body: [],
    });
  }
}
