import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { DisabledControlDirective } from './directives/disabled-control.direcitve';
import { IsCurrentRoleDisabledMessagingDirective } from './directives/is-current-role-disabled-messaging.directive';
import { MatTabGroupScrollFixDirective } from './directives/mat-tab-group-scroll-fix.directive';
import { MvpRestrictedDirective } from './directives/mvp-restricted.directive';
import { NgModelDebouncedDirective } from './directives/ng-model-debounced.directive';
import { DialogModule } from './modules/dialog/dialog.module';
import { TooltipModule } from './modules/tooltip/tooltip.module';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';

@NgModule({
  imports: [CommonModule, SvgIconComponent],
  declarations: [
    NgModelDebouncedDirective,
    MvpRestrictedDirective,
    MatTabGroupScrollFixDirective,
    DisabledControlDirective,
    IsCurrentRoleDisabledMessagingDirective,
    CurrencySymbolPipe,
    PhoneNumberPipe,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    DialogModule,
    MatRippleModule,
    TooltipModule,
    NgModelDebouncedDirective,
    MvpRestrictedDirective,
    MatTabGroupScrollFixDirective,
    DisabledControlDirective,
    IsCurrentRoleDisabledMessagingDirective,
    CurrencySymbolPipe,
    PhoneNumberPipe,
  ],
})
export class SharedModule {}
