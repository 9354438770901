import { inject, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { Config, CONFIG_TOKEN } from 'wtax-config';
import { environment } from '../../environments/environment';
import { Language } from '../shared/enums/language.enum';
import { translateLoaderProvider } from './providers/translate-loader.provider';
import { BlockScrollService } from './services/block-scroll.service';
import { GtmWrapperService } from './services/gtm-wrapper.service';
import { ROOT_REDUCERS } from './store/reducers/index';

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: Language.English,
      loader: translateLoaderProvider,
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),

    StoreModule.forRoot(ROOT_REDUCERS, {
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot(),
  ],
  providers: [
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: [Language.English] } },
    {
      provide: 'googleTagManagerId',
      useFactory: () => {
        const config = inject(CONFIG_TOKEN) as Config;
        return config.gtmContainerId;
      },
    },
  ],
})
export class CoreModule {
  constructor(
    private readonly gtmWrapperService: GtmWrapperService,
    public readonly blockScrollService: BlockScrollService
  ) {
    this.gtmWrapperService.startTracking();
  }
}
