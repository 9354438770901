import { BlockScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DialogService } from '@shared/modules/dialog/dialog.service';
import { LoadingIndicatorService } from '@shared/modules/loading-indicator/services/loading-indicator.service';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

/**
 * This service solves the problem when the class that supposed to prevent scrolling disappears.
 * The reason behind it is that if we use both dialogs and loading indicators at the same time,
 * sometimes the blocking class gets removed accidentaly.
 * So instead of using BlockScrollStrategy for both of them separately we use NoopScrollStrategy there,
 * and manage the scroll blocking here for both of them.
 */
@Injectable({
  providedIn: 'root',
})
export class BlockScrollService {
  constructor(
    viewportRuler: ViewportRuler,
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    @Inject(DOCUMENT) document: Document,
    dialogService: DialogService,
    loadingIndicatorService: LoadingIndicatorService
  ) {
    const blockScrollStrategy = new BlockScrollStrategy(viewportRuler, document);

    combineLatest([dialogService.hasOpenDialog$(), loadingIndicatorService.isVisible$()])
      .pipe(map((values) => values.some(Boolean), distinctUntilChanged()))
      .subscribe((shouldBeEnabled) => {
        if (shouldBeEnabled) {
          blockScrollStrategy.enable();
        } else {
          blockScrollStrategy.disable();
        }
      });
  }
}
