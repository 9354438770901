import { Message } from '@modules/messages/interfaces/message.interface';
import { mapAttachmentResponse } from '@shared/helpers/map-attachment-response.helper';
import { Message as MessageResponse } from '@wtax/data-angular';
import { mapMessageContactResponse } from './map-message-contact-response.helper';

export const mapMessagesResponse = (response: MessageResponse[]): Message[] =>
  response.map((message) => ({
    id: message.id,
    body: message.body,
    attachments: message.attachments?.map((attachment) => mapAttachmentResponse(attachment)),
    date: message.message_date,
    sender: mapMessageContactResponse(message.sender),
  }));
