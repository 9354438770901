import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { EmbeddedStatusMessageService } from '@core/services/embedded-status-message.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusMessageType } from '@shared/modules/status-message/enums/status-message-type.enum';
import isNil from 'lodash/isNil';
import { Config, CONFIG_TOKEN } from 'wtax-config';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackComponent implements OnInit {
  private readonly sessionIdKey: string = 'sessionId';
  private readonly sourceKey: string = 'source';
  private readonly redirectUrlKey: string = 'redirectUrl';

  public showUi: boolean;
  public sessionId: string;
  public source: string;
  public redirectUrl?: string | null;
  public localHost = 'localhost:4200';

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly embeddedStatusMessageService: EmbeddedStatusMessageService,
    private readonly translateService: TranslateService
  ) {
    this.showUi = config.debugAuth;
  }

  public ngOnInit(): void {
    this.sessionId = this.route.snapshot.queryParamMap.get(this.sessionIdKey);
    this.source = this.route.snapshot.queryParamMap.get(this.sourceKey);
    this.redirectUrl = this.route.snapshot.queryParamMap.get(this.redirectUrlKey);

    if (!this.config.debugAuth) {
      this.continue();
    }
  }

  public continue(): void {
    // eslint-disable-next-line no-console
    console.log(`[AUTH-CALLBACK] callback with ${this.sessionId} from ${this.source}`);

    this.authService.setAccessToken(this.sessionId);

    const redirectUrl = !isNil(this.redirectUrl) && this.redirectUrl.startsWith('/') ? this.redirectUrl.replace(/\/$/, '') : '';
    this.router.navigateByUrl(redirectUrl, { replaceUrl: true }).then(() => {
      if (this.source === 'register') {
        this.embeddedStatusMessageService.showMessage({
          type: StatusMessageType.Success,
          text: this.translateService.instant('AUTH_CALLBACK.SUCCESSFUL_REGISTRATION'),
        });
      }
    });
  }

  public redirectToLocalhost(): void {
    window.location.href = `http://${this.localHost}${this.router.url}`;
  }
}
