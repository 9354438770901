import { Inject, Injectable, NgZone } from '@angular/core';
import { ErrorNotificationService } from '@core/services/error-notification.service';
import { LoadingIndicatorService } from '@shared/modules/loading-indicator/services/loading-indicator.service';
import { MessagesDataService } from '@wtax/data-angular';
import isNil from 'lodash/isNil';
import { BehaviorSubject, EMPTY, interval, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';
import { Config, CONFIG_TOKEN } from 'wtax-config';
import { mapCreateMessagePayload } from '../helpers/map-create-message-payload.helper';
import { mapMessageContactPayload } from '../helpers/map-message-contact-payload.helper';
import { mapMessageContactsResponse } from '../helpers/map-message-contact-response.helper';
import { mapMessageThreadResponse, mapMessageThreadsResponse } from '../helpers/map-message-thread-response.helper';
import { mapMessagesResponse } from '../helpers/map-messages-response.helper';
import { CreateMessagePayload } from '../interfaces/create-message-payload.interface';
import { MessageContact } from '../interfaces/message-contact.interface';
import { MessageThread } from '../interfaces/message-thread.interface';
import { Message } from '../interfaces/message.interface';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private readonly messageThreadsCache$ = new BehaviorSubject<MessageThread[] | undefined>(undefined);

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly ngZone: NgZone,
    private readonly messagesDataService: MessagesDataService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {}

  public getCachedMessageThreads$(): Observable<MessageThread[]> {
    return this.messageThreadsCache$.pipe(
      switchMap((cachedMessageThreads) => (isNil(cachedMessageThreads) ? this.getMessageThreads$() : of(cachedMessageThreads)))
    );
  }

  public getMessageThreads$(): Observable<MessageThread[]> {
    return this.messagesDataService.getMessageThreads().pipe(
      map((response) => mapMessageThreadsResponse(response)),
      tap((messageThreads) => this.messageThreadsCache$.next(messageThreads)),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'COMMON.ERROR.FAILED_TO_LOAD_MESSAGES');
        return throwError(error);
      })
    );
  }

  public getMessageThread$(id: string): Observable<MessageThread> {
    return this.messagesDataService.getMessageThread(id).pipe(
      map((response) => mapMessageThreadResponse(response)),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'COMMON.ERROR.FAILED_TO_LOAD_MESSAGES');
        return throwError(error);
      })
    );
  }

  public getLastMessageThread$(): Observable<MessageThread> {
    return this.messagesDataService.getLatestMessageThread().pipe(
      map((response) => mapMessageThreadResponse(response)),
      catchError(() =>
        // in case of error there's no last message found
        // we need to show the new message dialog without error
        of(undefined)
      )
    );
  }

  public getMessages$(id: string): Observable<Message[]> {
    return this.messagesDataService.getMessages(id).pipe(
      map((response) => mapMessagesResponse(response)),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'COMMON.ERROR.FAILED_TO_LOAD_MESSAGES');
        return throwError(error);
      })
    );
  }

  public pollMessageThreads$(): Observable<MessageThread[]> {
    return this.ngZone.runOutsideAngular(() =>
      interval(this.config.messagePollingRateMs).pipe(
        switchMap(() => this.getMessageThreads$()),
        startWith([] as MessageThread[]),
        pairwise(),
        filter(([previousMessageThreads, loadedMessageThreads]) => {
          if (previousMessageThreads.length === 0 || previousMessageThreads.length !== loadedMessageThreads.length) {
            return true;
          }

          return loadedMessageThreads.some((messageThread) => {
            const previousMatchingMessageThread = previousMessageThreads.find((previousThread) => previousThread.id === messageThread.id);
            return messageThread.lastMessageDate !== previousMatchingMessageThread.lastMessageDate;
          });
        }),
        catchError(() => EMPTY),
        map(([_, loadedMessageThreads]) => this.ngZone.run(() => loadedMessageThreads))
      )
    );
  }

  public pollMessages$(id: string): Observable<Message[]> {
    return this.ngZone.runOutsideAngular(() =>
      interval(this.config.messagePollingRateMs).pipe(
        switchMap(() => this.getMessages$(id)),
        startWith([]),
        pairwise(),
        filter(([previousMessages, loadedMessages]) => {
          if (previousMessages.length === 0) {
            return true;
          }

          const previousLastMessage = previousMessages[previousMessages.length - 1];
          const currentLastMessage = loadedMessages[loadedMessages.length - 1];
          return previousLastMessage?.id !== currentLastMessage?.id;
        }),
        catchError(() => EMPTY),
        map(([_, loadedMessages]) => this.ngZone.run(() => loadedMessages))
      )
    );
  }

  public getMessageContacts$(): Observable<MessageContact[]> {
    return this.messagesDataService.getMessageContacts().pipe(
      map((response) => mapMessageContactsResponse(response)),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'COMMON.ERROR.FAILED_TO_LOAD_MESSAGE_CONTACTS');
        return throwError(error);
      })
    );
  }

  public readMessageThread$(id: string): Observable<MessageThread> {
    return this.messagesDataService.readMessageThread(id).pipe(
      map((response) => mapMessageThreadResponse(response)),
      tap(() => this.messageThreadsCache$.next(undefined)),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'COMMON.ERROR.FAILED_TO_MARK_MESSAGE_READ');
        return throwError(error);
      })
    );
  }

  public addParticipant$(id: string, contact: MessageContact): Observable<MessageThread> {
    this.loadingIndicatorService.open();
    return this.messagesDataService.addParticipant(id, mapMessageContactPayload(contact)).pipe(
      map((response) => mapMessageThreadResponse(response)),
      finalize(() => this.loadingIndicatorService.dispose()),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'COMMON.ERROR.FAILED_TO_LOAD_MESSAGE_CONTACTS');
        return EMPTY;
      })
    );
  }

  public sendMessage$(id: string, message: CreateMessagePayload): Observable<MessageThread> {
    return this.messagesDataService.sendMessage(id, mapCreateMessagePayload(message)).pipe(
      map((response) => mapMessageThreadResponse(response)),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'COMMON.ERROR.FAILED_TO_SEND_MESSAGE');
        return EMPTY;
      })
    );
  }
}
