import { ConnectedOverlayPositionChange, VerticalConnectionPos } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as fromProfile from '@core/store/reducers';
import { ControlValueAccessor } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { isCurrentUserCustodian } from '@shared/helpers/user-role.helper';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { DropdownComponent } from './dropdown.component';
import { OptionComponent } from './option.component';
import { SelectService } from './select.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    SelectService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements AfterContentInit, OnDestroy, ControlValueAccessor {
  @Input() public placeholder = '';
  @Input() public disabled = false;
  @Input() public multiple = false;
  @Input() public showTriggerIcon = true;
  @Input() public custodianColors = false;
  @Output() public infiniteScroll: EventEmitter<void> = new EventEmitter();

  public readonly isCurrentUserCustodian$ = this.store$.pipe(select(isCurrentUserCustodian));

  @ViewChild('input', { static: true })
  public input: ElementRef;

  @ViewChild(DropdownComponent, { static: true })
  public dropdown: DropdownComponent;

  @ContentChildren(OptionComponent)
  public options: QueryList<OptionComponent>;

  public selectedOptions: OptionComponent[] = [];
  public selectedValues: any[] = [];

  public displayText = '';
  public dropdownPositionClass?: VerticalConnectionPos;

  public dropdownShown = false;

  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(
    private readonly selectService: SelectService,
    private readonly cdr: ChangeDetectorRef,
    private readonly store$: Store<fromProfile.State>
  ) {
    this.selectService.register(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChangeFn = (_: any): void => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouchedFn = (): void => {};

  public ngAfterContentInit(): void {
    this.options.changes.pipe(startWith(this.options), takeUntil(this.destroyed$)).subscribe(() => {
      this.selectedOptions = this.getSelectedOptions();
      this.displayText = this.getDisplayText();
      this.cdr.markForCheck();
      this.options.forEach((option: OptionComponent) => option.refresh());
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public get displayOptions(): OptionComponent[] {
    return this.options.toArray();
  }

  private adaptValue(value: any): any[] {
    return isArray(value) ? value : [value];
  }

  private getSelectedOptions(): OptionComponent[] {
    return this.options.filter((option: OptionComponent) => this.selectedValues.some((value: any) => option.value === value));
  }

  public getDisplayText(): string {
    if (isEmpty(this.selectedOptions)) {
      return '';
    }
    return this.multiple ? this.selectedOptions.map((option) => option.getViewValue()).join(', ') : this.selectedOptions[0].getViewValue();
  }

  public showDropdown(): void {
    this.dropdown.show();
  }

  public hideDropdown(): void {
    this.dropdown.hide();
  }

  public onDropdownPositionChanges(change: ConnectedOverlayPositionChange): void {
    this.dropdownPositionClass = change.connectionPair.originY;
  }

  public triggerDropdownDisplay(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    event.stopPropagation();
    this.showDropdown();
  }

  public selectOption(option: OptionComponent): void {
    if (this.multiple) {
      this.handleMultiSelection(option);
    } else {
      this.handleSingleSelection(option);
    }
    this.cdr.markForCheck();
  }

  private handleMultiSelection(option: OptionComponent): void {
    this.selectedValues = this.selectedOptions.includes(option)
      ? this.selectedValues.filter((value: any) => value !== option.value)
      : [...this.selectedValues, option.value];
    this.selectedOptions = this.selectedOptions.includes(option)
      ? this.selectedOptions.filter((selectedOption: OptionComponent) => selectedOption !== option)
      : [...this.selectedOptions, option];
    this.displayText = this.getDisplayText();
    this.onChange();
  }

  private handleSingleSelection(option: OptionComponent): void {
    if (this.selectedOptions.includes(option)) {
      return;
    }
    this.selectedValues = [option.value];
    this.selectedOptions = [option];
    this.displayText = this.getDisplayText();
    this.onChange();
    this.hideDropdown();
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public setDropdownShown(isShown: boolean): void {
    this.dropdownShown = isShown;
    if (!this.dropdownShown) {
      this.dropdownPositionClass = undefined;
    }
  }

  public writeValue(obj: any): void {
    this.selectedValues = this.adaptValue(obj);
    if (isEmpty(this.options)) {
      return;
    }
    this.selectedOptions = this.options.filter((option: OptionComponent) =>
      this.multiple ? obj.includes(option.value) : option.value === obj
    );
    this.displayText = this.getDisplayText();
    this.cdr.markForCheck();
  }

  public onTouched(): void {
    this.onTouchedFn();
  }

  public onChange(): void {
    const value = this.multiple ? this.selectedValues : this.selectedValues[0];
    this.onChangeFn(value);
  }

  public triggerInfiniteScroll(): void {
    this.infiniteScroll.emit();
  }
}
