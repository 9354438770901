import { createReducer, on } from '@ngrx/store';
import { Account } from '@wtax/data-angular';
import * as AccountSelectionActions from '../actions/account-selection.actions';

export const accountSelectionFeatureKey = 'accountSelection';

export interface State {
  accounts: Account[];
}

export const initialState: State = {
  accounts: [],
};

export const reducer = createReducer<State>(
  initialState,
  on(AccountSelectionActions.loadAccountsSuccess, (state, { accounts }) => ({
    ...state,
    accounts,
  }))
);
