import { RouteSegment } from '@shared/enums/route-segment.enum';
import { UserRole } from '@wtax/data-angular';

export const roleLandingMap: ReadonlyMap<UserRole, string[]> = new Map<UserRole, string[]>([
  [UserRole.WEALTH_MANAGER, [RouteSegment.Root, RouteSegment.ClientManagement]],
  [UserRole.FUND_MANAGER, [RouteSegment.Root, RouteSegment.FundManagement]],
  [UserRole.BENEFICIAL_OWNER, [RouteSegment.Root, RouteSegment.YourTasks]],
  [UserRole.INVESTOR, [RouteSegment.Root, RouteSegment.YourTasks]],
  [UserRole.CUSTODIAN, [RouteSegment.Root, RouteSegment.Custodian]],
]);
