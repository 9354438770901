import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { mapUserProfile } from '@shared/helpers/map-user-profile.helper';
import { LoadingIndicatorService } from '@shared/modules/loading-indicator/services/loading-indicator.service';
import { ProfileDataService } from '@wtax/data-angular';
import isNil from 'lodash/isNil';
import { Observable, of } from 'rxjs';
import { finalize, mapTo, switchMap, tap } from 'rxjs/operators';
import { Config, CONFIG_TOKEN } from 'wtax-config';
import * as UserProfileActions from '../store/actions/user-profile.actions';
import * as fromRoot from '../store/reducers';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly authService: AuthService,
    private readonly store$: Store<fromRoot.State>,
    private readonly profileDataService: ProfileDataService,
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {}

  public canActivate(): Observable<boolean> | boolean {
    // NOTE: only check in guard if access token is present
    //       validity is checked with AuthInterceptor
    const accessToken = this.authService.getAccessToken();

    if (accessToken) {
      return this.store$.pipe(
        select(fromRoot.selectUserProfile),
        switchMap((userProfile) => {
          if (isNil(userProfile)) {
            this.loadingIndicatorService.open();
            return this.profileDataService.getUserProfile().pipe(
              tap((profile) =>
                this.store$.dispatch(
                  UserProfileActions.storeUserProfile({ profile: mapUserProfile(profile, this.config.fallbackCurrency) })
                )
              ),
              finalize(() => this.loadingIndicatorService.dispose())
            );
          }
          return of(userProfile);
        }),
        mapTo(true)
      );
    }

    // eslint-disable-next-line no-console
    console.log('[AUTH-GUARD] blocking route');
    this.authService.login();
    return false;
  }
}
