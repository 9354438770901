export enum Feature {
  Questions = 'questions',
  QuestionsTabs = 'questions-tabs',
  Dashboard = 'dashboard',
  AccountSetupExtraUsers = 'account-setup-extra-users',
  MessageClient = 'message-client',
  MessageAddParticipant = 'message-add-participant',
  MessagingTabs = 'messaging-tabs',
  Messaging = 'messaging',
  InviteClient = 'invite-client',
  ClientStatusFilter = 'client-status-filter',
  ClientSorting = 'client-sorting',
  BulkUpload = 'bulk-upload',
  BoPdfDownload = 'bo-pdf-download',
  EstimatedValue = 'estimated-value',
  SignDocumentButton = 'sign-document-button',
  ReportingPage = 'reporting-page',
  ReportingLink = 'reporting-link',
  InsightsPage = 'insights-page',
}
