import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ContentChild,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { matSnackBarAnimations } from '@angular/material/snack-bar';
import { TOAST_MESSAGE_DATA } from './toast-mesage.tokens';
import { ToastMessageData } from './toast-message-data.interface';
import { ToastMessageIconDirective } from './toast-message-icon.directive';
import { ToastMessageType } from './toast-message-type.enum';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [matSnackBarAnimations.snackBarState],
})
export class ToastMessageComponent extends BasePortalOutlet {
  @Input() public type: ToastMessageType;
  @Input() public closeable = true;
  @Output() public dismiss = new EventEmitter<void>();
  @ViewChild(CdkPortalOutlet, { static: true }) private readonly portalOutlet: CdkPortalOutlet;
  @ContentChild(ToastMessageIconDirective, { read: TemplateRef }) public iconTemplate: TemplateRef<ToastMessageIconDirective>;

  @HostBinding('class') public get typeClass(): string {
    switch (this.type) {
      case ToastMessageType.Success:
        return 'success';
      case ToastMessageType.Warning:
        return 'warning';
      case ToastMessageType.Error:
        return 'error';
    }
  }

  @HostBinding('@state') public get hostAnimation(): string {
    return this.animationState;
  }

  public animationState = 'void';

  constructor(@Optional() @Inject(TOAST_MESSAGE_DATA) private readonly data: ToastMessageData) {
    super();

    if (!data) {
      return;
    }

    this.type = data.config.type;
    this.closeable = data.config.closeable;
  }

  public enter(): void {
    this.animationState = 'visible';
  }

  public attachComponentPortal<C>(portal: ComponentPortal<C>): ComponentRef<C> {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  public attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
    return this.portalOutlet.attachTemplatePortal(portal);
  }

  public onCloseClick(): void {
    this.dismiss.emit();

    if (this.data) {
      this.data.ref.close();
    }
  }
}
