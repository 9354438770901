import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../../../../core/store/reducers';
import * as fromAccountSelection from './account-selection.reducer';

export const accountSelectionFeatureKey = 'accountSelection';

export interface AccountSelectionState {
  [fromAccountSelection.accountSelectionFeatureKey]: fromAccountSelection.State;
}

export interface AppState extends State {
  [accountSelectionFeatureKey]: AccountSelectionState;
}

export const reducers: ActionReducerMap<AccountSelectionState> = {
  [fromAccountSelection.accountSelectionFeatureKey]: fromAccountSelection.reducer,
};

export const selectAccountSelectionFeatureState = createFeatureSelector<AppState, AccountSelectionState>(accountSelectionFeatureKey);
export const selectAccountSelectionState = createSelector(
  selectAccountSelectionFeatureState,
  (state) => state[fromAccountSelection.accountSelectionFeatureKey]
);

export const selectAccountSelection = createSelector(selectAccountSelectionState, (state) => state.accounts);
