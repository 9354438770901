export enum MenuName {
  Dashboard = 'dashboard',
  ClientAction = 'client_action',
  Refunds = 'refunds',
  Messages = 'messages',
  Reporting = 'reporting',
  Insights = 'insights',
  Logo = 'logo',
  TaxFormPrePopulation = 'tax_form_pre_population',
  AddOnTaxServices = 'add_on_tax_services',
}
