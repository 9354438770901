import { Injectable } from '@angular/core';
import { selectUserRole, State } from '@core/store/reducers';
import { select, Store } from '@ngrx/store';
import { BankingDetailsEvents } from '@shared/enums/GoogleTagManager/banking-details-events.enum';
import { ClaimantDetailsEvents } from '@shared/enums/GoogleTagManager/claimant-details-events.enum';
import { DocumentRequestEvents } from '@shared/enums/GoogleTagManager/document-request-events.enum';
import { PageSubType } from '@shared/enums/GoogleTagManager/document-request-page-subtype.enum';
import { DocumentUploadType } from '@shared/enums/GoogleTagManager/document-upload-type.enum';
import { DocumentsEvents } from '@shared/enums/GoogleTagManager/documents-events.enum';
import { PageType } from '@shared/enums/GoogleTagManager/documents-page-type.enum';
import { InviteUserType } from '@shared/enums/GoogleTagManager/invite-user-type';
import { InvitesEvents } from '@shared/enums/GoogleTagManager/invites-events.enum';
import { MenuItemEvents } from '@shared/enums/GoogleTagManager/menu-item-events.enum';
import { MenuName } from '@shared/enums/GoogleTagManager/menu-name-enum';
import { MessagesEvents } from '@shared/enums/GoogleTagManager/messages-events.enum';
import { QuestionnaireEvents } from '@shared/enums/GoogleTagManager/questionnaire-events.enum';
import { RefundsEvents } from '@shared/enums/GoogleTagManager/refund-events.enum';
import { SignatoryDetailEvents } from '@shared/enums/GoogleTagManager/signatory-detail-events.enum';
import { NavigationSource } from '@shared/modules/document-management/enums/navigation-source.enum';
import { UserRole } from '@wtax/data-angular';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable } from 'rxjs';

class GtmService {
  public readonly userRole$: Observable<UserRole> = this.store$.pipe(select(selectUserRole));
  public userRole: UserRole;

  constructor(
    private readonly gtmService: GoogleTagManagerService,
    private readonly store$: Store<State>
  ) {
    this.userRole$.subscribe((role: UserRole) => {
      this.userRole = role;
    });
  }
  public pushTag(eventName: string, paramteres?: Record<string, string>): void {
    if (paramteres !== undefined) {
      this.gtmService.pushTag({
        event: eventName,
        role: this.userRole,
        ...paramteres,
      });
    } else {
      this.gtmService.pushTag({
        event: eventName,
        role: this.userRole,
      });
    }
  }
}

@Injectable({ providedIn: 'root' })
export class DocumentRequestGoogleTagManagerService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }
  public pushUploadDocumentClickTag(uploadType: DocumentUploadType): void {
    this.pushTag(DocumentRequestEvents.UploadDocumentClick, {
      page_subtype: PageSubType.Upload,
      upload_type: uploadType,
    });
  }

  public pushUploadDocumentSuccessTag(uploadType: DocumentUploadType): void {
    this.pushTag(DocumentRequestEvents.UploadDocumentSuccess, {
      page_subtype: PageSubType.Upload,
      upload_type: uploadType,
    });
  }

  public pushUploadDocumentErrorTag(uploadType: DocumentUploadType): void {
    this.pushTag(DocumentRequestEvents.UploadDocumentError, {
      page_subtype: PageSubType.Upload,
      upload_type: uploadType,
    });
  }

  public pushNeedHelpClickTag() {
    this.pushTag(DocumentRequestEvents.NeedHelpClick);
  }

  public pushSignClickedTag(pageType: NavigationSource) {
    this.pushTag(DocumentRequestEvents.SignClick, {
      page_subtype: PageSubType.Digital,
      type: pageType,
    });
  }

  public pushSignSuccessfullyTag(pageType: NavigationSource) {
    this.pushTag(DocumentRequestEvents.SignSuccess, {
      page_subtype: PageSubType.Digital,
      type: pageType,
    });
  }

  public pushSignErrorTag(pageType: NavigationSource) {
    this.pushTag(DocumentRequestEvents.SignError, {
      page_subtype: PageSubType.Digital,
      type: pageType,
    });
  }

  public pushDocumentRequestDownloadClickTag(pageType: string) {
    this.pushTag(DocumentRequestEvents.DownloadClick, {
      page_subtype: PageSubType.Physical,
      type: pageType,
    });
  }

  public pushDocumentRequestDownloadClickSuccessTag(pageType: string) {
    this.pushTag(DocumentRequestEvents.DownloadClickSucess, {
      page_subtype: PageSubType.Physical,
      type: pageType,
    });
  }

  public pushDocumentRequestDownloadClickErrorTag(pageType: string, statusCode: string) {
    this.pushTag(DocumentRequestEvents.DownloadClickError, {
      page_subtype: PageSubType.Physical,
      type: pageType,
      errorId: statusCode,
    });
  }
}
@Injectable({ providedIn: 'root' })
export class SignatoryDetailsGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }

  public pushSignatoryDetailsBrowseTag() {
    this.pushTag(SignatoryDetailEvents.SignatoryDetailsBrowse);
  }

  public pushSignatoryDetailsUploadSuccessTag() {
    this.pushTag(SignatoryDetailEvents.SignatoryDetailsUploadSuccess);
  }

  public pushSignatoryDetailsUploadFailedTag() {
    this.pushTag(SignatoryDetailEvents.SignatoryDetailsUploadFailed);
  }

  public pushSignatoryDetailSavedTag() {
    this.pushTag(SignatoryDetailEvents.SignatoryDetailsSave);
  }
}

@Injectable({ providedIn: 'root' })
export class DocumentsGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }

  public pushToolTipClickTag() {
    this.pushTag(DocumentsEvents.ToolTipClick, {
      page_type: PageType.Documents,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class MessagesGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }
  public pushMessagesClickTag() {
    this.pushTag(MessagesEvents.MessagesClick);
  }
}

@Injectable({ providedIn: 'root' })
export class MenuItemGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }

  public pushMenuItemClickedTag(menuItemName: string) {
    this.pushTag(MenuItemEvents.MenuItemClicked, {
      menu_name: this.getmenuItemName(menuItemName),
    });
  }

  private getmenuItemName(menuItemName: string): string {
    switch (menuItemName) {
      case 'HEADER.DASHBOARD':
        return MenuName.Dashboard;
      case 'HEADER.MAKE_CLAIM':
        return MenuName.ClientAction;
      case 'HEADER.REFUNDS':
        return MenuName.Refunds;
      case 'HEADER.MESSAGES':
        return MenuName.Messages;
      case 'HEADER.REPORTING':
        return MenuName.Reporting;
      case 'HEADER.INSIGHTS':
        return MenuName.Insights;
      case 'LOGO':
        return MenuName.Logo;
      case 'HEADER.TAX_FORM_PRE_POPULATION':
        return MenuName.TaxFormPrePopulation;
      case 'HEADER.ADD_ON_TAX_SERVICES.ADD_ON_TAX_SERVICES_HEADER':
        return MenuName.AddOnTaxServices;
      default:
        return '';
    }
  }
}

@Injectable({ providedIn: 'root' })
export class BankingDetailsGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }

  public pushConfirmBankingDetailsClickTag() {
    this.pushTag(BankingDetailsEvents.ConfirmBankingDetailsClick);
  }
}

@Injectable({ providedIn: 'root' })
export class ClaimantDetailsGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }
  public pushAddClaimantDetailsClickTag() {
    this.pushTag(ClaimantDetailsEvents.AddClaimantDetailsClick);
  }

  public pushDownloadClaimantDetailsDataSheetTag() {
    this.pushTag(ClaimantDetailsEvents.DownloadClaimantDetailsDataSheet);
  }

  public pushClaimantDetailsBrowseTag() {
    this.pushTag(ClaimantDetailsEvents.ClaimantDetailsBrowse);
  }

  public pushClaimantDetailsUploadSuccessTag() {
    this.pushTag(ClaimantDetailsEvents.ClaimantDetailsUploadSuccess);
  }

  public pushClaimantDetailsUplaodFailed() {
    this.pushTag(ClaimantDetailsEvents.ClaimantDetailsUplaodFailed);
  }

  public pushClaimantDetailsSave() {
    this.pushTag(ClaimantDetailsEvents.ClaimantDetailsSave);
  }
}

@Injectable({ providedIn: 'root' })
export class RefundGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }

  public pushRefundClickTag() {
    this.pushTag(RefundsEvents.RefundClick);
  }

  public pushRefundDownloadTag() {
    this.pushTag(RefundsEvents.RefundDownload);
  }
}

@Injectable({ providedIn: 'root' })
export class QuestionnaireEventsGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }
  public pushQuestionnaireToolTipClickTag() {
    this.pushTag(QuestionnaireEvents.QuestionnaireToolTipClick);
  }

  public pushQuestionanaireSubmitTag() {
    this.pushTag(QuestionnaireEvents.QuestionanaireSubmit);
  }
}

@Injectable({ providedIn: 'root' })
export class InvitesEventsGtmService extends GtmService {
  constructor(gtmService: GoogleTagManagerService, store$: Store<State>) {
    super(gtmService, store$);
  }
  public pushInviteUsersClickTag() {
    this.pushTag(InvitesEvents.InviteUsersClick);
  }

  public pushInviteUserEmailSaveTag() {
    this.pushTag(InvitesEvents.InviteUserEmailSave);
  }

  public pushInviteUsersSuccessTag(inviteType: InviteUserType) {
    this.pushTag(InvitesEvents.InviteUsersSuccess, {
      invite_type: inviteType,
    });
  }

  public pushInviteUsersErrorTag() {
    this.pushTag(InvitesEvents.InviteUsersError);
  }

  public pushSendReminderClickTag() {
    this.pushTag(InvitesEvents.SendReminderClick);
  }
}
