import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { merge, Observable, race, throwError, timer } from 'rxjs';
import { catchError, filter, first, publish, refCount, switchMapTo } from 'rxjs/operators';
import { Config, CONFIG_TOKEN } from 'wtax-config';

const RETRY_COUNT = 3;
const REQUEST_TIMEOUT = 5000;

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  private readonly timeout$: Observable<never> = timer(this.config.httpRetryTimeoutMs || REQUEST_TIMEOUT).pipe(
    switchMapTo(throwError(new HttpErrorResponse({ status: 0 })))
  );

  constructor(@Inject(CONFIG_TOKEN) private readonly config: Config) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET' && request.url.startsWith(this.config.apiBaseUrl)) {
      return this.retry$(request, next);
    }
    return next.handle(request);
  }

  private retry$(
    request: HttpRequest<any>,
    next: HttpHandler,
    count = this.config.httpRetryCount || RETRY_COUNT
  ): Observable<HttpEvent<any>> {
    const handledRequest$ = next.handle(request).pipe(publish(), refCount());
    return merge(
      handledRequest$.pipe(filter((event) => !(event instanceof HttpResponse))),
      race(handledRequest$.pipe(first((event) => event instanceof HttpResponse)), this.timeout$)
    ).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 0 || count === 0) {
          return throwError(error);
        }
        return this.retry$(request, next, count - 1);
      })
    );
  }
}
