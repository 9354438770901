import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Attachment, UploadBase64AttachmentRequest } from '@wtax/data-angular';
import { randomId } from '../functions';

export class AttachmentsMockService {
  private readonly store: Map<string, Attachment>;

  constructor() {
    this.store = new Map<string, Attachment>();
  }

  private createRandomAttachment(): Attachment {
    const id = randomId();
    return {
      id,
      file_name: `fund-data-sheet-${id}.pdf`,
      file_size: Math.floor(Math.random() * 1000),
      mime_type: 'application/pdf',
      url: `https://downloaddocument.com/${id}`,
    };
  }

  public getStoredAttachmentsByIds(ids: string[]): Attachment[] {
    return ids.map((id) => this.store.get(id)).filter((doc) => !!doc);
  }

  public createAndStoreRandomAttachments(maxAttachments = 3): Attachment[] {
    const attachmentsCount = Math.floor(Math.random() * maxAttachments + 1);
    const attachments = new Array(attachmentsCount).fill(null).map(() => this.createRandomAttachment());
    attachments.forEach((d) => this.store.set(d.id, d));
    return attachments;
  }

  public getAttachment(request: HttpRequest<any>): HttpResponse<Attachment> {
    const id = new URL(request.url).pathname.substring(11);
    const attachment = this.store.get(id);

    return new HttpResponse({ status: 200, body: attachment });
  }

  public getAttachmentsByIds(request: HttpRequest<any>): HttpResponse<Attachment[]> {
    const ids = request.params.get('ids').split(',');
    const attachments: Attachment[] = [];

    ids.forEach((id) => {
      const attachment = this.store.get(id);

      if (attachment) {
        attachments.push(attachment);
      }
    });

    return new HttpResponse({ status: 200, body: attachments });
  }

  public uploadAttachment(request: HttpRequest<any>): HttpResponse<Attachment> {
    const id = randomId();

    const attachment: Attachment = {
      id,
      file_name: request.body.get('file_name'),
      file_size: request.body.get('file').size,
      mime_type: request.body.get('file').type,
      url: `https://downloaddocument.com/${id}`,
    };

    this.store.set(id, attachment);

    return new HttpResponse({ status: 200, body: attachment });
  }

  public uploadBase64Attachment(request: HttpRequest<UploadBase64AttachmentRequest>): HttpResponse<Attachment> {
    const id = randomId();

    const attachment: Attachment = {
      id,
      file_name: request.body.file_name,
      file_size: 12345,
      mime_type: 'application/pdf',
      url: `https://downloaddocument.com/${id}`,
    };

    this.store.set(id, attachment);

    return new HttpResponse({ status: 200, body: attachment });
  }
}
