import { HttpRequest } from '@angular/common/http';
import isNil from 'lodash/isNil';

export const parseToken = (request: HttpRequest<any>): string | null => {
  const authorizationHeader = request.headers.get('Authorization');
  if (isNil(authorizationHeader)) {
    return null;
  }
  return authorizationHeader.substring(7);
};
