import { DocumentType } from '@wtax/data-angular';

const uploadDocumentTypeNames = {
  DIVIDEND: ['Bank Statements'],
  CLIENT: [
    'Letter of Authority',
    'Fund Information',
    'Proof of Tax Exemption',
    'Prospectus/Fund fact Sheet',
    'UCITS Attestation/Supervisory Authority Certificate',
  ],
  CLAIM: ['Claim Form'],
};
export const randomUploadDocumentTypeName = (documentType: DocumentType): string => {
  const namesForDocumentType = uploadDocumentTypeNames[documentType];
  const randomIndex = Math.floor(Math.random() * namesForDocumentType.length);
  return namesForDocumentType[randomIndex];
};
