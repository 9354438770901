import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { MenuItemGtmService } from '@core/services/google-tag-manager.service';
import * as fromProfile from '@core/store/reducers';
import { select, Store } from '@ngrx/store';
import { DesignFeature } from '@shared/enums/design-feature.enum';
import { isCurrentUserCustodian, isCurrentUserInstitutions, isCurrentUserPrivateWealth } from '@shared/helpers/user-role.helper';
import { Config, CONFIG_TOKEN } from 'wtax-config';
import { MenuItemType } from './menu-item-type.enum';
import { MenuItem } from './menu-item.interface';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent {
  @Input() public menuItem: MenuItem;
  @Input() public disabled: boolean;

  public readonly MenuItemType = MenuItemType;
  public readonly isNewNavColor = this.config.designFeatures.includes(DesignFeature.Fonts);
  public readonly isCurrentUserCustodian$ = this.store$.pipe(select(isCurrentUserCustodian));
  public readonly isCurrentUserPrivateWealth$ = this.store$.pipe(select(isCurrentUserPrivateWealth));
  public readonly isCurrentUserInstitutions$ = this.store$.pipe(select(isCurrentUserInstitutions));

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly gtmService: MenuItemGtmService,
    private readonly store$: Store<fromProfile.State>
  ) {}

  public onmenuItemClick(menuItem: MenuItem): void {
    this.gtmService.pushMenuItemClickedTag(menuItem.name);
  }
}
