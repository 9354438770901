import { createReducer, on } from '@ngrx/store';
import { Profile } from '@shared/interfaces/profile.interface';
import * as UserProfileActions from '../actions/user-profile.actions';

export const userProfileKey = 'userProfile';

export interface State {
  userProfile: Profile | null;
}

export const initialState: State = { userProfile: null };

export const reducer = createReducer<State>(
  initialState,
  on(UserProfileActions.storeUserProfile, (state, { profile }) => ({ ...state, userProfile: profile })),
  on(UserProfileActions.storeUserProfileCompleteness, (state, { completeness }) => ({
    ...state,
    userProfile: { ...state.userProfile, accountCompleteness: completeness },
  })),
  on(UserProfileActions.storeUserProfileSignatoryDetailsStatus, (state, { signatoryDetailsStatus }) => ({
    ...state,
    userProfile: { ...state.userProfile, signatoryDetailsStatus },
  }))
);
