export enum DocumentRequestEvents {
  NeedHelpClick = 'document_request_need_help_click',
  SignClick = 'document_request_sign_click',
  SignSuccess = 'document_request_sign_success',
  SignError = 'document_request_sign_error',
  UploadDocumentError = 'document_request_upload_document_error',
  UploadDocumentSuccess = 'document_request_upload_document_success',
  UploadDocumentClick = 'document_request_upload_document_click',
  DownloadClick = 'document_request_download_click',
  DownloadClickSucess = 'document_request_download_success',
  DownloadClickError = 'document_request_download_error',
}
