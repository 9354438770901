import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import * as fromProfile from '@core/store/reducers';
import { select, Store } from '@ngrx/store';
import { DesignFeature } from '@shared/enums/design-feature.enum';
import { VERSION } from 'version';
import { Config, CONFIG_TOKEN } from 'wtax-config';
import { isCurrentUserCustodian, isCurrentUserInstitutions, isCurrentUserPrivateWealth } from './shared/helpers/user-role.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public version: string;
  public readonly DesignFeature = DesignFeature;
  public readonly isNewDesignStyle = this.config.designFeatures.includes(DesignFeature.Header);
  public readonly newFontStyle = this.config.designFeatures.includes(DesignFeature.Fonts);
  public readonly isCurrentUserCustodian$ = this.store$.pipe(select(isCurrentUserCustodian));
  public readonly isCurrentUserPrivateWealth$ = this.store$.pipe(select(isCurrentUserPrivateWealth));
  public readonly isCurrentUserInstitutions$ = this.store$.pipe(select(isCurrentUserInstitutions));

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly store$: Store<fromProfile.State>
  ) {
    this.version = VERSION;
  }
}
