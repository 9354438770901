<div class="d-flex align-items-center w-100">
  <div *ngIf="iconTemplate" class="icon mr-3">
    <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
  </div>
  <div class="d-flex justify-content-between align-items-start flex-grow-1">
    <div class="message">
      <ng-content></ng-content>
      <ng-template cdkPortalOutlet></ng-template>
    </div>
    <button *ngIf="closeable" mat-icon-button type="button" (click)="onCloseClick()" class="button button-tertiary close-button">
      <svg-icon key="close"></svg-icon>
    </button>
  </div>
</div>
