import { Directive, HostListener, Inject } from '@angular/core';
import { DIALOG_DATA } from '../dialog.tokens';
import { DialogData } from '../interfaces/dialog-data.interface';

@Directive({
  selector: '[appDialogClose]',
})
export class DialogCloseDirective {
  constructor(@Inject(DIALOG_DATA) private readonly data: DialogData) {}

  @HostListener('click')
  public onClick(): void {
    this.data.dialogRef.close();
  }
}
