<div class="container py-0-5">
  <a [routerLink]="rootLink$ | async" (click)="onLogoClick()">
    <img class="logo" *ngIf="isNewLogo; else currentLogo" src="assets/images/new-design/logo.svg" alt="WTax" />
    <ng-template #currentLogo>
      <img class="logo" src="assets/images/logo.svg" alt="WTax" />
    </ng-template>
  </a>

  <app-account-dropdown
    #accountDropdown
    *ngIf="(isCurrentUserFundOrWealthManager$ | async) === true"
    (accountSelectedEvent)="accountSelected($event)"
  >
  </app-account-dropdown>

  <nav class="navigation" [class.disabled]="accountCreationInProgress$ | async">
    <app-navigation-item
      *ngFor="let item of items$ | async"
      class="navigation-item"
      [menuItem]="item"
      [disabled]="accountCreationInProgress$ | async"
    ></app-navigation-item>
  </nav>

  <div #profile class="profile" [class.active]="profileVisible$ | async">
    <svg-icon class="new-login-icon" *ngIf="isNewLoginIcon; else currentLoginIcon" key="loginIconStatic"></svg-icon>
    <ng-template #currentLoginIcon>
      <svg-icon #currentLoginIcon key="profile"></svg-icon>
    </ng-template>
    <ul class="profile-menu" [class.visible]="profileVisible$ | async">
      <li *ngIf="hasAccountSettings$ && (isCurrentUserCustodian$ | async) === false" [class.disabled]="accountCreationInProgress$ | async">
        <a [routerLink]="(accountCreationInProgress$ | async) === false ? myAccountLink : undefined">{{
          'HEADER.MY_ACCOUNT' | translate
        }}</a>
      </li>
      <li>
        <span (click)="onLogoutClick()">{{ 'HEADER.LOGOUT' | translate }}</span>
      </li>
    </ul>
  </div>
</div>
