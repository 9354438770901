import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Attachment, ClientType, Discretion, DiscretionBatch, DiscretionsMetadata, DiscretionStatus } from '@wtax/data-angular';
import { paginate, parseToken, randomBool, randomDate, randomElement, randomEnumValue, randomId } from '../functions';
import { AttachmentsMockService } from './attachments-mock.service';

const CLIENT_COUNT = 100;
const FIRST_NAMES = [
  'Liam',
  'Noah',
  'Oliver',
  'William',
  'Elijah',
  'James',
  'Benjamin',
  'Lucas',
  'Mason',
  'Ethan',
  'Alexander',
  'Henry',
  'Jacob',
  'Michael',
  'Daniel',
  'Logan',
  'Jackson',
  'Sebastian',
  'Jack',
  'Aiden',
  'Olivia',
  'Emma',
  'Ava',
  'Sophia',
  'Isabella',
  'Charlotte',
  'Amelia',
  'Mia',
  'Harper',
  'Evelyn',
  'Abigail',
  'Emily',
  'Ella',
  'Elizabeth',
  'Camila',
  'Luna',
  'Sofia',
  'Avery',
  'Mila',
  'Aria',
];
const LAST_NAMES = [
  'Smith',
  'Johnson',
  'Williams',
  'Brown',
  'Jones',
  'Garcia',
  'Miller',
  'Davis',
  'Rodriguez',
  'Martinez',
  'Hernandez',
  'Lopez',
  'Gonzalez',
  'Wilson',
  'Anderson',
  'Thomas',
  'Taylor',
  'Moore',
  'Jackson',
  'Martin',
];
const BUSINESS_NAMES = [
  'Eco Focus',
  'Innovation Arch',
  'Strat Security',
  'Inspire Fitness Co',
  'Candor Corp',
  'Cogent Data',
  'Epic Adventure Inc',
  'Sanguine Skincare',
  'Vortex Solar',
  'Admire Arts',
  'Bravura Inc',
  'Bonefete Fun',
  'Moxie Marketing',
  'Zeal Wheels',
  'Obelus Concepts',
  'Quad Goals',
  'Erudite Learning',
  'Cipher Publishing',
  'Flux Water Gear',
  'Lambent Illumination',
  'Ryan, Stamm and Ebert',
  'Fadel - Muller',
  'Reilly LLC',
  'Medhurst Group',
  'Aufderhar LLC',
  'Cassin LLC',
  'Kohler, Lubowitz and Langosh',
  'Nienow Group',
  'Gulgowski - Okuneva',
  'Roob LLC',
  'Simonis and Sons',
  'Bartoletti - Effertz',
  'Rath, Bayer and Rempel',
  'Grady, Renner and West',
  'Botsford, Dickinson and Schoen',
  'Lynch, Hills and Romaguera',
  'Hand, Gislason and Ritchie',
  'Marquardt - McClure',
  'Murray LLC',
  'Lowe, Kuhic and Buckridge',
];

export class DiscretionManagementMockService {
  private wmDiscretions: Discretion[] = [];
  private processingDocumentsCount = 0;

  constructor(private readonly attachmentsMockService: AttachmentsMockService) {
    // WM clients
    for (let i = 0; i < CLIENT_COUNT; i++) {
      const type = randomBool() ? ClientType.NATURAL_PERSON : randomBool() ? ClientType.JURISTIC_ENTITY : ClientType.TRUST;
      let name: string;
      if (type === ClientType.NATURAL_PERSON) {
        name = `${randomElement(FIRST_NAMES)} ${randomElement(LAST_NAMES)}`;
      } else {
        name = randomElement(BUSINESS_NAMES);
      }
      const claimsExpiring = randomBool();
      const status = randomEnumValue(DiscretionStatus);

      const attachments: Attachment[] =
        status === DiscretionStatus.UPLOADED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [];

      this.wmDiscretions.push({
        client_id: randomId(),
        client_code: randomId(),
        client_name: name,
        status,
        claims_expiring: claimsExpiring,
        claims_expiring_at: claimsExpiring ? randomDate(new Date().toISOString().split('T')[0], '2021-01-31') : null,
        attachments,
      });
    }
    this.wmDiscretions = this.wmDiscretions.sort((a) => (a.claims_expiring ? -1 : 1));
  }

  public getDiscretionsMetadata(request: HttpRequest<any>): HttpResponse<DiscretionsMetadata> {
    const token = parseToken(request);

    let list: Discretion[];

    if (token.startsWith('WM')) {
      list = this.wmDiscretions;
    }

    const statusMap: Map<DiscretionStatus, number> = new Map<DiscretionStatus, number>();

    list.forEach((client) => {
      let statusCount = statusMap.get(client.status);
      if (!statusCount) {
        statusCount = 0;
      }
      statusCount++;
      statusMap.set(client.status, statusCount);
    });

    const validStatuses = Array.from(statusMap.keys());

    const metadata: DiscretionsMetadata = {
      total_count: list.length,
      status_counts: validStatuses.map((status) => ({
        status,
        count: statusMap.get(status),
      })),
      claims_expiring_count: list.filter((c) => c.claims_expiring).length,
      processing_count: this.processingDocumentsCount,
    };

    return new HttpResponse({ status: 200, body: metadata });
  }

  public getDiscretions(request: HttpRequest<any>): HttpResponse<DiscretionBatch> {
    const token = parseToken(request);
    const limit = Number(request.params.get('limit'));
    const offset = Number(request.params.get('offset'));
    const name = request.params.get('client_name');
    const statuses = request.params.get('statuses');

    let list: Discretion[];

    if (token.startsWith('WM')) {
      list = this.wmDiscretions;
    }

    if (name) {
      list = list.filter((client) => {
        if (!client.client_name) {
          return false;
        }

        return client.client_name.includes(name);
      });
    }

    if (statuses) {
      const parsedStatuses = statuses.split(',') as DiscretionStatus[];

      list = list.filter((client) => parsedStatuses.includes(client.status));
    }

    const clientBatch: DiscretionBatch = {
      items: paginate(list, limit, offset),
      total_count: list.length,
    };

    return new HttpResponse({ status: 200, body: clientBatch });
  }

  public submitDiscretionClientDocuments(request: HttpRequest<{ attachment_ids: string[] }>, id: string): HttpResponse<void> {
    const client = this.wmDiscretions.find((d) => d.client_id === id);
    if (!client) {
      return new HttpResponse({ status: 404 });
    }
    client.status = DiscretionStatus.UPLOADED;
    client.attachments = this.attachmentsMockService.getStoredAttachmentsByIds(request.body.attachment_ids);
    return new HttpResponse({ status: 200 });
  }

  public submitBulkDiscretionDocuments(request: HttpRequest<{ attachment_ids: string[] }>): HttpResponse<void> {
    this.processingDocumentsCount = this.processingDocumentsCount + request.body.attachment_ids.length;
    return new HttpResponse({ status: 200 });
  }
}
