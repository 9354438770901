import { HttpRequest, HttpResponse } from '@angular/common/http';
import {
  AccountCompleteness,
  AccountStatus,
  DiscretionAuthorization,
  ReportType,
  SignatoryDetailsStatus,
  UserProfile,
  UserRole,
} from '@wtax/data-angular';
import { parseToken, randomBool, randomElement, randomId } from '../functions';
import { AccountMockService } from './account-mock.service';

const defaultCurrency = randomElement(['USD', 'EUR', 'UAH', 'GBP', 'HUF', 'JPY']);
const totalEstimatedValue = randomBool() ? { value: Math.floor(Math.random() * 100000), currency: defaultCurrency } : null;

export class ProfileMockService {
  constructor(private readonly accountMockService: AccountMockService) {}

  public getCurrentUserProfile(token: string): UserProfile {
    if (token.startsWith('WM')) {
      return wmProfilePartialDiscretion;
    } else if (token.startsWith('FM')) {
      return fmProfile;
    } else if (token.startsWith('BO')) {
      return boProfile;
    } else if (token.startsWith('INVESTOR')) {
      return investorProfile;
    } else if (token.startsWith('CUSTODIAN')) {
      return custodianProfile;
    }
  }

  public getUserProfile(request: HttpRequest<any>): HttpResponse<UserProfile> {
    const token = parseToken(request);

    let body: UserProfile;
    let status: AccountStatus;

    if (token.startsWith('WM') && token.indexOf('discretion=yes-for-all') >= 0) {
      body = wmProfileFullDiscretion;
      status = this.accountMockService.wmStatus();
    } else if (token.startsWith('WM') && token.indexOf('discretion=yes-for-some') >= 0) {
      body = wmProfilePartialDiscretion;
      status = this.accountMockService.wmStatus();
    } else if (token.startsWith('WM') && token.indexOf('discretion=none') >= 0) {
      body = wmProfileNoneDiscretion;
      status = this.accountMockService.wmStatus();
    } else if (token.startsWith('WM') && token.indexOf('discretion=null') >= 0) {
      body = wmProfileNullDiscretion;
      status = this.accountMockService.wmStatus();
    } else if (token.startsWith('FM')) {
      body = fmProfile;
      status = this.accountMockService.fmStatus();
      body.signatory_details_status = this.accountMockService.signatoryDetailsStatus();
    } else if (token.startsWith('BO')) {
      body = boProfile;
      status = this.accountMockService.boStatus();
    } else if (token.startsWith('INVESTOR')) {
      body = investorProfile;
      status = this.accountMockService.investorStatus();
    } else if (token.startsWith('CUSTODIAN')) {
      body = custodianProfile;
      status = this.accountMockService.custodianStatus();
    } else {
      body = wmProfileFullDiscretion;
      status = this.accountMockService.wmStatus();
    }

    if (body) {
      if (token === 'WM:bos-not-paid-directly') {
        body.beneficial_owners_paid_directly = false;
      }

      body.account_completeness = status.completeness;
      return new HttpResponse({ status: 200, body });
    }

    return new HttpResponse({ status: 401 });
  }
}

const wmProfileFullDiscretion: UserProfile = {
  id: '1',
  name: {
    title: 'Ms.',
    first_name: 'Wealth',
    last_name: 'Manager',
  },
  email: 'wealth.manager@company.com',
  role: UserRole.WEALTH_MANAGER,
  account_completeness: AccountCompleteness.INCOMPLETE,
  has_payments_to_confirm: false,
  beneficial_owners_paid_directly: true,
  discretion_authorization: DiscretionAuthorization.YES_FOR_ALL,
  account_manager: {
    id: randomId(),
    name: {
      title: 'Mr.',
      first_name: 'Arthur',
      last_name: 'Morgan',
    },
    role: 'Account Manager',
  },
  default_currency_code: defaultCurrency,
  office_address: '54 Melrose Boulevard, Melrose Arch, Melrose North, 2196, Johannesburg, South Africa',
  total_estimated_value: totalEstimatedValue,
  restricted_to_account_setup: false,
};

const wmProfilePartialDiscretion: UserProfile = {
  id: '1',
  name: {
    title: 'Ms.',
    first_name: 'Wealth',
    last_name: 'Manager',
  },
  email: 'wealth.manager@company.com',
  role: UserRole.WEALTH_MANAGER,
  account_completeness: AccountCompleteness.INCOMPLETE,
  has_payments_to_confirm: false,
  beneficial_owners_paid_directly: true,
  discretion_authorization: DiscretionAuthorization.YES_FOR_SOME,
  account_manager: {
    id: randomId(),
    name: {
      title: 'Mr.',
      first_name: 'Arthur',
      last_name: 'Morgan',
    },
    role: 'Account Manager',
  },
  default_currency_code: defaultCurrency,
  office_address: '54 Melrose Boulevard, Melrose Arch, Melrose North, 2196, Johannesburg, South Africa',
  total_estimated_value: totalEstimatedValue,
  restricted_to_account_setup: false,
  report_type: ReportType.POWERBI_REPORTING,
};

const wmProfileNoneDiscretion: UserProfile = {
  id: '1',
  name: {
    title: 'Ms.',
    first_name: 'Wealth',
    last_name: 'Manager',
  },
  email: 'wealth.manager@company.com',
  role: UserRole.WEALTH_MANAGER,
  account_completeness: AccountCompleteness.INCOMPLETE,
  has_payments_to_confirm: false,
  beneficial_owners_paid_directly: true,
  discretion_authorization: DiscretionAuthorization.NONE,
  account_manager: {
    id: randomId(),
    name: {
      title: 'Mr.',
      first_name: 'Arthur',
      last_name: 'Morgan',
    },
    role: 'Account Manager',
  },
  default_currency_code: defaultCurrency,
  office_address: '54 Melrose Boulevard, Melrose Arch, Melrose North, 2196, Johannesburg, South Africa',
  total_estimated_value: totalEstimatedValue,
  restricted_to_account_setup: false,
};

const wmProfileNullDiscretion: UserProfile = {
  id: '1',
  name: {
    title: 'Ms.',
    first_name: 'Wealth',
    last_name: 'Manager',
  },
  email: 'wealth.manager@company.com',
  role: UserRole.WEALTH_MANAGER,
  account_completeness: AccountCompleteness.INCOMPLETE,
  has_payments_to_confirm: false,
  beneficial_owners_paid_directly: true,
  discretion_authorization: null,
  account_manager: {
    id: randomId(),
    name: {
      title: 'Mr.',
      first_name: 'Arthur',
      last_name: 'Morgan',
    },
    role: 'Account Manager',
  },
  default_currency_code: defaultCurrency,
  office_address: '54 Melrose Boulevard, Melrose Arch, Melrose North, 2196, Johannesburg, South Africa',
  total_estimated_value: totalEstimatedValue,
  restricted_to_account_setup: false,
};

const fmProfile: UserProfile = {
  id: '2',
  name: {
    title: 'Mr.',
    first_name: 'Fund',
    last_name: 'Manager',
  },
  email: 'fund.manager@company.com',
  role: UserRole.FUND_MANAGER,
  account_completeness: AccountCompleteness.INCOMPLETE,
  has_payments_to_confirm: false,
  beneficial_owners_paid_directly: null,
  signatory_details_status: SignatoryDetailsStatus.NOT_UPLOADED,
  account_manager: {
    id: randomId(),
    name: {
      title: 'Mr.',
      first_name: 'Arthur',
      last_name: 'Morgan',
    },
    role: 'Account Manager',
  },
  default_currency_code: defaultCurrency,
  office_address: '54 Melrose Boulevard, Melrose Arch, Melrose North, 2196, Johannesburg, South Africa',
  total_estimated_value: totalEstimatedValue,
  restricted_to_account_setup: false,
  report_type: ReportType.REPORTING_PORTAL,
};

const boProfile: UserProfile = {
  id: '3',
  name: {
    title: 'Mrs.',
    first_name: 'Benefical',
    last_name: 'Owner',
  },
  email: 'benefical.owner@gmail.com',
  role: UserRole.BENEFICIAL_OWNER,
  account_completeness: AccountCompleteness.INCOMPLETE,
  has_payments_to_confirm: false,
  beneficial_owners_paid_directly: null,
  account_manager: {
    id: randomId(),
    name: {
      title: 'Mr.',
      first_name: 'Arthur',
      last_name: 'Morgan',
    },
    role: 'Account Manager',
  },
  default_currency_code: defaultCurrency,
  office_address: '54 Melrose Boulevard, Melrose Arch, Melrose North, 2196, Johannesburg, South Africa',
  total_estimated_value: totalEstimatedValue,
  restricted_to_account_setup: randomBool(),
  report_type: ReportType.REPORTING_PORTAL,
};

const investorProfile: UserProfile = {
  id: '3',
  name: {
    title: 'Mr.',
    first_name: 'Professor',
    last_name: 'Stonks',
  },
  email: 'professor.stonks@gmail.com',
  role: UserRole.INVESTOR,
  account_completeness: AccountCompleteness.INCOMPLETE,
  has_payments_to_confirm: false,
  beneficial_owners_paid_directly: null,
  account_manager: {
    id: randomId(),
    name: {
      title: 'Mr.',
      first_name: 'Arthur',
      last_name: 'Morgan',
    },
    role: 'Account Manager',
  },
  default_currency_code: defaultCurrency,
  office_address: '54 Melrose Boulevard, Melrose Arch, Melrose North, 2196, Johannesburg, South Africa',
  restricted_to_account_setup: false,
};

const custodianProfile: UserProfile = {
  id: '4',
  name: {
    title: 'Mr.',
    first_name: 'City Bank',
    last_name: 'Manager',
  },
  email: 'citybank.manager@company.com',
  role: UserRole.CUSTODIAN,
  account_completeness: AccountCompleteness.COMPLETED,
  has_payments_to_confirm: false,
  beneficial_owners_paid_directly: null,
  signatory_details_status: SignatoryDetailsStatus.NOT_UPLOADED,
  account_manager: {
    id: randomId(),
    name: {
      title: 'Mr.',
      first_name: 'Arthur',
      last_name: 'Morgan',
    },
    role: 'Account Manager',
  },
  default_currency_code: defaultCurrency,
  office_address: '54 Melrose Boulevard, Melrose Arch, Melrose North, 2196, Johannesburg, South Africa',
  total_estimated_value: totalEstimatedValue,
  restricted_to_account_setup: false,
  report_type: ReportType.REPORTING_PORTAL,
};
