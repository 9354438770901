import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

@Directive({
  selector: '[appInfiniteScroll]',
})
export class InfiniteScrollDirective {
  @Output() public scrollEnd: EventEmitter<void> = new EventEmitter();
  private readonly thresholdMultiplier = 0.001;
  private lastOffset = 0;

  @HostListener('scroll', ['$event'])
  public handleScroll(event: Event): void {
    const clientHeight: number | undefined = get(event, 'target.clientHeight');
    const scrollHeight: number | undefined = get(event, 'target.scrollHeight');
    const scrollTop: number | undefined = get(event, 'target.scrollTop');
    if (isNil(clientHeight) || isNil(scrollHeight) || isNil(scrollTop)) {
      return;
    }
    const offset = scrollHeight - scrollTop;
    const isScrollingDown = offset <= this.lastOffset;
    const thresholdHeight = clientHeight + clientHeight * this.thresholdMultiplier;
    this.lastOffset = offset;
    if (!isScrollingDown) {
      return;
    }
    if (offset < thresholdHeight) {
      this.scrollEnd.emit();
    }
  }
}
