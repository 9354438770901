<div
  class="guide-card-container"
  [class.inverted-colors]="invertedColors"
  [class.borderless]="borderless"
  [class.custodian-colors]="custodianColors"
  [class.custodian-inverted-colors]="custodianInvertedColors"
  [class.private-wealth-colors]="privateWealthColors"
  [class.private-wealth-inverted-colors]="privateWealthInvertedColors"
>
  <div *ngIf="icon" class="icon" [class.icon-background]="isIconBackgroundVisible">
    <ng-container *ngTemplateOutlet="icon"></ng-container>
  </div>
  <div class="content">
    <div *ngIf="title" class="title" [class.collapse-title]="description && !collapsable" [class.bold-title]="isTitleBold">
      <ng-container *ngTemplateOutlet="title"></ng-container>
      <ng-container *ngIf="(isCurrentUserPrivateWealth$ | async) === false; else pWguideCard">
        <div
          *ngIf="collapsable"
          class="toggle-icon"
          [class.turned]="!isOpen"
          (click)="onToggleCardClick()"
          [ngStyle]="(isCurrentUserCustodian$ | async) === false ? { color: '#72d28d' } : { color: '#3a5074' }"
        >
          <svg-icon *ngIf="isNewArrowIcon; else currentArrowIcon" key="arrowDown"></svg-icon>
          <ng-template #currentArrowIcon>
            <svg-icon key="buttonAccordionUp"></svg-icon>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #pWguideCard>
        <div *ngIf="collapsable" class="private-wealth-toggle-icon" [class.turned]="!isOpen" (click)="onToggleCardClick()">
          <svg-icon key="arrowDown"></svg-icon>
        </div>
      </ng-template>
    </div>
    <div *ngIf="description && isOpen" class="description">
      <ng-container *ngTemplateOutlet="description"></ng-container>
    </div>
  </div>
</div>
