import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TooltipButtonComponent } from './tooltip-icon/tooltip-button.component';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective, TooltipButtonComponent],
  imports: [CommonModule, OverlayModule, SvgIconComponent, MatRippleModule],
  exports: [TooltipDirective, TooltipButtonComponent],
})
export class TooltipModule {}
