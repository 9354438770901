<div hidden>{{ version }}</div>
<div class="page-container" [ngClass]="{ 'new-font-style': newFontStyle === true }">
  <app-header
    [ngClass]="{
      'new-design': (isCurrentUserInstitutions$ | async),
      'custodian-colors': (isCurrentUserCustodian$ | async) === true,
      'private-wealth-colors': (isCurrentUserPrivateWealth$ | async) === true,
    }"
  ></app-header>
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
