import { HttpRequest } from '@angular/common/http';
import isNil from 'lodash/isNil';

export const parseAccountId = (request: HttpRequest<any>): string | null => {
  const accountIdHeader = request.headers.get('X-WTax-Account-ID');
  if (isNil(accountIdHeader)) {
    return null;
  }
  return accountIdHeader;
};
