import { Inject, Injectable, Injector } from '@angular/core';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { Configuration } from '@wtax/data-angular';
import { Config, CONFIG_TOKEN } from 'wtax-config';

const ACCESS_TOKEN_KEY = 'access_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly redirectUrlKey = 'redirectUrl';

  constructor(
    private readonly injector: Injector,
    @Inject(CONFIG_TOKEN) private readonly config: Config
  ) {}

  public login(): void {
    if (this.config.apiMocking) {
      this.setAccessToken('WM:discretion=yes-for-all');
      window.location.href = RouteSegment.Root;
      return;
    }

    const url = new URL(this.config.loginRedirectUrl);
    url.searchParams.append(this.redirectUrlKey, location.pathname);
    window.location.href = url.toString();
  }

  public logout(): void {
    this.setAccessToken(null);

    if (this.config.apiMocking) {
      window.location.href = RouteSegment.Root;
      return;
    }

    // log out in SF as well
    window.location.href = this.config.logoutRedirectUrl;
  }

  public getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  public setAccessToken(accessToken: string): void {
    const apiConfig = this.injector.get(Configuration);

    if (!accessToken) {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      apiConfig.accessToken = null;

      return;
    }

    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    apiConfig.accessToken = accessToken;
  }
}
