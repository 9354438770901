import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { SharedModule } from '@shared/shared.module';
import { DropdownComponent } from './dropdown.component';
import { OptionComponent } from './option.component';
import { SelectComponent } from './select.component';

@NgModule({
  imports: [CommonModule, SharedModule, PortalModule, MatRippleModule, MatCheckboxModule],
  declarations: [OptionComponent, SelectComponent, DropdownComponent],
  exports: [OptionComponent, SelectComponent, DropdownComponent],
})
export class SelectModule {}
