import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { FormPopulation } from '@wtax/data-angular';
import { randomElement, randomId } from '../functions';

const CLIENT_CODE = ['342435345AD', '3425D3424UX', '548433458DE'];
const FORM_NAME = ['Form NR7-R', 'Form NR301', 'Form 5000 & Form 5001'];

export class FormPopulationMockService {
  private formPopulations: FormPopulation[] = [];

  constructor() {
    for (let index = 0; index < 5; index++) {
      const client_id = randomId();
      const client_code = randomElement(CLIENT_CODE);
      const form_name = randomElement(FORM_NAME);
      const id = randomId();
      const completed_form_url = index % 2 === 0 ? `https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf` : null;

      const formPopulation: FormPopulation = {
        id,
        client_id,
        form_name,
        client_code,
        completed_form_url,
      };

      this.formPopulations.push(formPopulation);
    }
  }

  public getFormPopulationRequestTemplate(_: HttpRequest<any>): HttpResponse<any> {
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'text/csv',
      }),
      body:
        'Beneficial Owner Reference Number,Beneficial Owner Name,Beneficial Owner ID,' +
        'Form NR7-R,Form NR301,Form NR302,Form NR303,Form NR304,Form 5000 & Form 5001,' +
        'French,Annex II,Attestation - Article 131 - Sexies I,Form V2A,Form V2B,Form V2C,' +
        'Form V3,Form IC6,Dividends Withholding Tax Refund Claim Form - REIT,' +
        'Dividends Withholding Tax Refund Claim Form,Modelo 210\n' +
        'BO0001,Beneficial Owner 1,a0t0Y000002YvC7QAK\n' +
        'BO0002,Beneficial Owner 2,a0t0Y000002YvC8QAK\n' +
        'BO0003,Beneficial Owner 3,a0t0Y000002YvC9QAK',
    });
  }

  public createFormPopulations(_: HttpRequest<any>): HttpResponse<any> {
    return new HttpResponse({ status: 200 });
  }

  public getFormPopulations(request: HttpRequest<any>): HttpResponse<any> {
    const completedParam: boolean | null = request.params.has('completed') ? request.params.get('completed') === 'true' : null;
    return new HttpResponse({
      status: 200,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body:
        completedParam != null
          ? this.formPopulations.filter((formPopulation) => (formPopulation.completed_form_url != null) === completedParam)
          : this.formPopulations,
    });
  }
}
