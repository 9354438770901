export const bytesInMegaByte = 1048576;
export const DEBOUNCE_RATE = 500;
export const CURRENT_USER_CLIENT_ID = '-';
export const WILDCARD_PARAMETER = '-';
export const PAGE_ITEMS_LIMIT = 10;
export const NAME_TITLE_MAX_LENGTH = 20;
export const MIN_VISIBLE_ENTRIES_OF_COLLAPSIBLE_TABLE = 2;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PARTICIPANT_TO_SHOW_COUNT = 2;
export const MAX_SIGNATURE_COUNT = 25;
export const EMAIL_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9._|%#~`=?&/$^*!}{+-]+@[a-zA-Z0-9][a-zA-Z0-9.-]*[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
