import { mapAttachmentResponse } from '@shared/helpers/map-attachment-response.helper';
import { MessageThread as MessageThreadResponse } from '@wtax/data-angular';
import { MessageThread } from '../interfaces/message-thread.interface';
import { mapClientSummaryResponse } from './map-client-summary-response.helper';
import { mapMessageContactsResponse } from './map-message-contact-response.helper';

export const mapMessageThreadsResponse = (response: MessageThreadResponse[]): MessageThread[] =>
  response.map((messageThread) => mapMessageThreadResponse(messageThread));

export const mapMessageThreadResponse = (response: MessageThreadResponse): MessageThread => ({
  id: response.id,
  participants: mapMessageContactsResponse(response.participants),
  lastMessageExtract: response.last_message_extract,
  status: response.status,
  hasAttachment: response.has_attachment,
  lastMessageDate: response.last_message_date,
  unreadMessages: response.unread_messages_count,
  client: response.client ? mapClientSummaryResponse(response.client) : undefined,
  icon: response.icon ? mapAttachmentResponse(response.icon) : undefined,
});
