import { Attachment } from '@shared/interfaces/attachment.interface';
import { Attachment as AttachmentResponse } from '@wtax/data-angular';

export const mapAttachmentResponse = (response: AttachmentResponse): Attachment => ({
  id: response.id,
  mimeType: response.mime_type,
  name: response.file_name,
  size: response.file_size,
  url: response.url,
});
