import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef } from '@angular/core';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TOAST_MESSAGE_DATA } from './toast-mesage.tokens';
import { ToastMessageConfig } from './toast-message-config.interface';
import { ToastMessageData } from './toast-message-data.interface';
import { ToastMessageRef } from './toast-message-ref';
import { ToastMessageType } from './toast-message-type.enum';
import { ToastMessageWrapperComponent } from './toast-message-wrapper.component';
import { ToastMessageComponent } from './toast-message.component';

@Injectable()
export class ToastMessageService {
  private readonly DEFAULT_CONFIG: ToastMessageConfig = {
    type: ToastMessageType.Success,
    duration: 5000,
    closeable: true,
  };

  private openRef: ToastMessageRef;

  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector
  ) {}

  public open(content: string | TemplateRef<any>, config?: ToastMessageConfig): ToastMessageRef {
    const configWithDefaults = { ...this.DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay();
    const ref = new ToastMessageRef();
    const injector = this.createInjector(configWithDefaults, ref);
    const toastMessage = overlayRef.attach(new ComponentPortal(ToastMessageComponent, undefined, injector));

    if (content instanceof TemplateRef) {
      toastMessage.instance.attachTemplatePortal(new TemplatePortal(content, undefined));
    } else {
      const componentRef = toastMessage.instance.attachComponentPortal(new ComponentPortal(ToastMessageWrapperComponent));
      componentRef.instance.content = content;
    }

    if (this.openRef) {
      this.openRef.afterClosed$().subscribe(() => toastMessage.instance.enter());
      this.openRef.close();
    } else {
      toastMessage.instance.enter();
    }

    ref.afterClosed$().subscribe(() => {
      overlayRef.detach();

      delete this.openRef;
    });

    timer(configWithDefaults.duration)
      .pipe(takeUntil(ref.afterClosed$()))
      .subscribe(() => ref.close());

    this.openRef = ref;

    return ref;
  }

  private createOverlay(): OverlayRef {
    return this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay.position().global().centerHorizontally().top('100px'),
      hasBackdrop: false,
      width: '700px',
      maxWidth: 'calc(100vw - 4rem)',
    });
  }

  private createInjector(config: ToastMessageConfig, ref: ToastMessageRef): Injector {
    const data: ToastMessageData = { config, ref };

    return Injector.create({ providers: [{ provide: TOAST_MESSAGE_DATA, useValue: data }], parent: this.injector });
  }
}
