import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ACCOUNT_ID_LOCAL_STORAGE_KEY } from '@shared/constants/local-storage-keys.constants';
import { Config, CONFIG_TOKEN } from '../../../wtax-config';

@Injectable({
  providedIn: 'root',
})
export class AccountSelectionInterceptor implements HttpInterceptor {
  constructor(@Inject(CONFIG_TOKEN) private readonly config: Config) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accountId = localStorage.getItem(ACCOUNT_ID_LOCAL_STORAGE_KEY);
    // only intercept API requests and where there is an accountId selected
    if (!request.url.startsWith(this.config.apiBaseUrl) || !accountId) {
      return next.handle(request);
    }
    request = request.clone({
      setHeaders: {
        'X-WTax-Account-ID': accountId,
      },
    });
    return next.handle(request);
  }
}
