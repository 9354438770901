import { Injectable } from '@angular/core';
import { OptionComponent } from './option.component';
import { SelectComponent } from './select.component';

@Injectable()
export class SelectService {
  private select: SelectComponent;

  public get multiple(): boolean {
    return this.select.multiple;
  }

  public register(select: SelectComponent): void {
    this.select = select;
  }

  public selectOption(option: OptionComponent): void {
    this.select.selectOption(option);
  }

  public isSelectedOption(option: OptionComponent): boolean {
    return this.select.selectedOptions.includes(option);
  }
}
