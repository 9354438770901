import { MessageContact } from '@modules/messages/interfaces/message-contact.interface';
import { mapName } from '@shared/helpers/map-name.helper';
import { MessageContact as MessageContactResponse } from '@wtax/data-angular';

export const mapMessageContactsResponse = (response: MessageContactResponse[]): MessageContact[] =>
  response.map((messageContact) => mapMessageContactResponse(messageContact));

export const mapMessageContactResponse = (response: MessageContactResponse): MessageContact => ({
  id: response.id,
  name: mapName(response.name),
  profileImage: response.profile_image,
  role: response.role,
});
