import { ChangeDetectionStrategy, Component, ContentChild, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import * as fromProfile from '@core/store/reducers';
import { select, Store } from '@ngrx/store';
import { DesignFeature } from '@shared/enums/design-feature.enum';
import { isCurrentUserCustodian, isCurrentUserPrivateWealth } from '@shared/helpers/user-role.helper';
import { Config, CONFIG_TOKEN } from 'wtax-config';
import { GuideCardDescriptionDirective } from './guide-card-description.directive';
import { GuideCardIconDirective } from './guide-card-icon.directive';
import { GuideCardTitleDirective } from './guide-card-title.directive';

@Component({
  selector: 'app-guide-card',
  templateUrl: './guide-card.component.html',
  styleUrls: ['./guide-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuideCardComponent implements OnInit {
  @Input() public borderless = false;
  @Input() public invertedColors = false;
  @Input() public custodianColors = false;
  @Input() public custodianInvertedColors = false;
  @Input() public privateWealthInvertedColors?: boolean = false;
  @Input() public privateWealthColors = false;
  @Input() public collapsable = false;
  @Input() public initiallyOpen = false;
  @Input() public isIconBackgroundVisible = true;
  @Input() public isTitleBold = true;
  public isOpen = true;
  public readonly isNewArrowIcon = this.config.designFeatures.includes(DesignFeature.NewIcons);
  public readonly isNewNavColor = false;
  public readonly isCurrentUserCustodian$ = this.store$.pipe(select(isCurrentUserCustodian));
  public readonly isCurrentUserPrivateWealth$ = this.store$.pipe(select(isCurrentUserPrivateWealth));

  @ContentChild(GuideCardIconDirective, { read: TemplateRef }) public icon: TemplateRef<any>;
  @ContentChild(GuideCardTitleDirective, { read: TemplateRef }) public title: TemplateRef<any>;
  @ContentChild(GuideCardDescriptionDirective, { read: TemplateRef }) public description: TemplateRef<any>;

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly store$: Store<fromProfile.State>
  ) {}

  public ngOnInit(): void {
    this.isOpen = this.collapsable ? this.initiallyOpen : true;
  }

  public onToggleCardClick(): void {
    this.isOpen = !this.isOpen;
  }
}
