<div #dropRef>
  <div
    [ngClass]="{
      'dropdown-trigger': (isCurrentUserCustodian$ | async) === false,
      'custodian-dropdown-trigger': (isCurrentUserCustodian$ | async) === true,
    }"
    [class]="dropdownPositionClass"
    [class.open]="dropdownShown"
    [class.disabled]="disabled"
    (click)="triggerDropdownDisplay($event)"
    (blur)="onTouched()"
  >
    <span class="value text-regular-normal">
      {{ selectedOptions.length > 0 ? displayText : ' ' }}
    </span>
    <span *ngIf="placeholder" class="placeholder text-regular-normal" [class.floating]="selectedOptions.length > 0">{{ placeholder }}</span>
    <svg-icon
      *ngIf="showTriggerIcon"
      key="chevronUp"
      [ngClass]="{
        'dropdown-trigger-icon': (isCurrentUserCustodian$ | async) === false,
        'custodian-dropdown-trigger-icon': (isCurrentUserCustodian$ | async) === true,
      }"
    ></svg-icon>
  </div>
  <app-dropdown
    [reference]="dropRef"
    (toggleShowing)="setDropdownShown($event)"
    (positionChanges)="onDropdownPositionChanges($event)"
    #dropdownComp
  >
    <div class="dropdown-options-container d-flex flex-column" [ngClass]="dropdownPositionClass">
      <ng-content select="app-option"></ng-content>
    </div>
  </app-dropdown>
</div>
