<div class="account">
  <div class="d-flex align-items-center text-regular-extra-bold label">
    <span>{{ label }} </span>
  </div>
  <app-select
    class="account-dropdown"
    [(ngModel)]="selectedAccount"
    (ngModelChange)="onSelect()"
    [multiple]="false"
    *ngIf="accounts$ | async as accounts"
  >
    <app-option *ngFor="let account of accounts" [value]="account.id">{{ account.name }}</app-option>
  </app-select>
</div>
