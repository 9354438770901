import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogOverlayComponent } from './components/dialog-overlay/dialog-overlay.component';
import { DialogCloseDirective } from './directives/dialog-close.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';

@NgModule({
  declarations: [DialogContainerComponent, DialogOverlayComponent, DialogCloseDirective, InfiniteScrollDirective],
  imports: [CommonModule, SvgIconComponent, OverlayModule, PortalModule],
  exports: [DialogCloseDirective, InfiniteScrollDirective],
})
export class DialogModule {}
