import { animate, state, style, transition, trigger } from '@angular/animations';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ComponentRef, EmbeddedViewRef, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DIALOG_DATA } from '../../dialog.tokens';
import { DialogType } from '../../enums/dialog-type.enum';
import { DialogData } from '../../interfaces/dialog-data.interface';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('state', [
      state('void, exit', style({ opacity: 0, transform: `translateY(50px)` })),
      transition(':enter', animate('250ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 }))),
      transition(':leave', animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 }))),
    ]),
  ],
})
export class DialogContainerComponent extends BasePortalOutlet implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: true }) private readonly portalOutlet: CdkPortalOutlet;

  @HostBinding('@state') public get hostAnimation(): string {
    return this.animationState;
  }

  @HostBinding('style.width') public get hostWidth(): string {
    return this.data.config.width;
  }

  private readonly destroy$ = new Subject<void>();
  private animationState = 'visible';

  constructor(@Inject(DIALOG_DATA) public readonly data: DialogData) {
    super();
  }

  public ngOnInit(): void {
    this.data.dialogRef
      .visible$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((visible) => {
        this.animationState = visible ? 'visible' : 'void';
      });
  }

  public get isPopupDialog(): boolean {
    return this.data.config.dialogType === DialogType.Popup;
  }

  public get isNewMessageDialog(): boolean {
    return this.data.config.dialogType === DialogType.NewMessage;
  }

  public attachComponentPortal<C>(portal: ComponentPortal<C>): ComponentRef<C> {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  public attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
    return this.portalOutlet.attachTemplatePortal(portal);
  }

  public onScrollEnd(): void {
    this.data.dialogRef.triggerScrollEnd();
  }
}
