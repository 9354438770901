import {
  ConnectedOverlayPositionChange,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnChanges {
  @Input() public reference: HTMLElement;
  @Output() public toggleShowing = new EventEmitter<boolean>();
  @Output() public positionChanges = new EventEmitter<ConnectedOverlayPositionChange>();

  @ViewChild(CdkPortal, { static: true })
  public contentTemplate: CdkPortal;

  private positionStrategy: FlexibleConnectedPositionStrategy;
  private overlayRef: OverlayRef;
  /**
   * Tracking events while dropdown is active
   * When dropdown closes, should unsubscribe
   */
  private activeDropDownSubscription: Subscription;

  constructor(
    private readonly overlay: Overlay,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly zone: NgZone
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.reference) {
      this.updatePositionStrategy();
    }
  }

  private updatePositionStrategy(): void {
    this.positionStrategy = this.createPositionStrategy();
    if (!isNil(this.overlayRef)) {
      this.overlayRef.updatePositionStrategy(this.positionStrategy);
    }
  }

  private createPositionStrategy(): FlexibleConnectedPositionStrategy {
    return this.overlayPositionBuilder
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: -1,
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);
  }

  public show(): void {
    if (isNil(this.overlayRef)) {
      this.overlayRef = this.overlay.create(this.getOverlayConfig());
    }
    this.overlayRef.attach(this.contentTemplate);
    this.syncWidth();
    this.activeDropDownSubscription = new Subscription();
    this.activeDropDownSubscription.add(this.overlayRef.backdropClick().subscribe(() => this.hide()));
    this.activeDropDownSubscription.add(fromEvent(window, 'resize').subscribe(() => this.syncWidth()));
    this.activeDropDownSubscription.add(
      this.positionStrategy.positionChanges
        .pipe(distinctUntilChanged(isEqual))
        .subscribe((change) => this.zone.run(() => this.positionChanges.emit(change)))
    );
    this.toggleShowing.emit(true);
  }

  public hide(): void {
    this.overlayRef.detach();
    this.activeDropDownSubscription.unsubscribe();
    this.toggleShowing.emit(false);
  }

  protected getOverlayConfig(): OverlayConfig {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    return new OverlayConfig({
      positionStrategy: this.positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }

  private syncWidth(): void {
    if (isNil(this.overlayRef)) {
      return;
    }

    const refRect = this.reference.getBoundingClientRect();
    this.overlayRef.updateSize({ width: refRect.width });
  }
}
