<ng-container *ngIf="(isCurrentUserPrivateWealth$ | async) === false">
  <svg
    [attr.width]="diameter"
    [attr.height]="diameter"
    attr.viewBox="0 0 {{ diameter }} {{ diameter }}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      fill="transparent"
      stroke="#DEE0E4"
      stroke-linecap="round"
      stroke-linejoin="round"
      [attr.stroke-width]="strokeWidth"
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.r]="innerRadius"
    ></circle>
    <circle
      class="loading"
      fill="transparent"
      stroke="#4DCCB4"
      stroke-linecap="round"
      stroke-linejoin="round"
      style.stroke-dasharray="{{ dashArray }}"
      style.stroke-dashoffset="{{ dashOffset }}"
      style.transform-origin="{{ radius }}px {{ radius }}px 0"
      [attr.stroke-width]="strokeWidth"
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.r]="innerRadius"
    ></circle>
  </svg>
</ng-container>
<ng-container *ngIf="(isCurrentUserPrivateWealth$ | async) === true">
  <svg
    [attr.width]="diameter"
    [attr.height]="diameter"
    attr.viewBox="0 0 {{ diameter }} {{ diameter }}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      fill="transparent"
      stroke="#EFE6F4"
      stroke-linecap="round"
      stroke-linejoin="round"
      [attr.stroke-width]="strokeWidth"
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.r]="innerRadius"
    ></circle>
    <circle
      class="loading"
      fill="transparent"
      stroke="#6B3D86"
      stroke-linecap="round"
      stroke-linejoin="round"
      style.stroke-dasharray="{{ dashArray }}"
      style.stroke-dashoffset="{{ dashOffset }}"
      style.transform-origin="{{ radius }}px {{ radius }}px 0"
      [attr.stroke-width]="strokeWidth"
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.r]="innerRadius"
    ></circle>
  </svg>
</ng-container>
