import { HttpRequest, HttpResponse } from '@angular/common/http';
import { CURRENT_USER_CLIENT_ID } from '@shared/constants/global.constants';
import {
  Attachment,
  BankingDetails,
  BulkInviteClientsRequest,
  BulkInviteClientsResponse,
  Client,
  ClientBatch,
  ClientDetails,
  ClientDocumentsMetadata,
  ClientDocumentsResponse,
  ClientInvite,
  ClientInviteBatch,
  ClientInvitesMetadata,
  ClientInviteStatus,
  ClientInviteStatusResponse,
  ClientQuestionsSummaryStatus,
  ClientsMetadata,
  ClientStatus,
  ClientType,
  DocumentDigitalStatus,
  DocumentOriginalStatus,
  DocumentType,
  DocumentTypesResponse,
  DocumentUploadStatus,
  FundClientDetails,
  FundDetails,
  JuristicClientDetails,
  JuristicDetails,
  NaturalClientDetails,
  PersonalDetails,
  QuestionsClientsBatch,
  QuestionsMetadata,
  RefundAnalysisProgress,
  RefundAnalysisStatus,
  SetClientEmailRequest,
  SubmitClientDetailsRequest,
  TaxDetails,
  TrustClientDetails,
  TrustDetails,
} from '@wtax/data-angular';
import isNil from 'lodash/isNil';
import { paginate, parseToken, randomBool, randomDate, randomElement, randomEnumValue, randomId } from '../functions';
import { randomUploadDocumentTypeName } from '../functions/random-document-type-name';
import {
  emptyBankingDetails,
  emptyFundDetails,
  emptyJuristicDetails,
  emptyPersonalDetails,
  emptyTaxDetails,
  emptyTrustDetails,
} from '../stubs';
import { AttachmentsMockService } from './attachments-mock.service';

const CLIENT_COUNT = 100;
const FIRST_NAMES = [
  'Liam',
  'Noah',
  'Oliver',
  'William',
  'Elijah',
  'James',
  'Benjamin',
  'Lucas',
  'Mason',
  'Ethan',
  'Alexander',
  'Henry',
  'Jacob',
  'Michael',
  'Daniel',
  'Logan',
  'Jackson',
  'Sebastian',
  'Jack',
  'Aiden',
  'Olivia',
  'Emma',
  'Ava',
  'Sophia',
  'Isabella',
  'Charlotte',
  'Amelia',
  'Mia',
  'Harper',
  'Evelyn',
  'Abigail',
  'Emily',
  'Ella',
  'Elizabeth',
  'Camila',
  'Luna',
  'Sofia',
  'Avery',
  'Mila',
  'Aria',
];
const LAST_NAMES = [
  'Smith',
  'Johnson',
  'Williams',
  'Brown',
  'Jones',
  'Garcia',
  'Miller',
  'Davis',
  'Rodriguez',
  'Martinez',
  'Hernandez',
  'Lopez',
  'Gonzalez',
  'Wilson',
  'Anderson',
  'Thomas',
  'Taylor',
  'Moore',
  'Jackson',
  'Martin',
];
const BUSINESS_NAMES = [
  'Eco Focus',
  'Innovation Arch',
  'Strat Security',
  'Inspire Fitness Co',
  'Candor Corp',
  'Cogent Data',
  'Epic Adventure Inc',
  'Sanguine Skincare',
  'Vortex Solar',
  'Admire Arts',
  'Bravura Inc',
  'Bonefete Fun',
  'Moxie Marketing',
  'Zeal Wheels',
  'Obelus Concepts',
  'Quad Goals',
  'Erudite Learning',
  'Cipher Publishing',
  'Flux Water Gear',
  'Lambent Illumination',
  'Ryan, Stamm and Ebert',
  'Fadel - Muller',
  'Reilly LLC',
  'Medhurst Group',
  'Aufderhar LLC',
  'Cassin LLC',
  'Kohler, Lubowitz and Langosh',
  'Nienow Group',
  'Gulgowski - Okuneva',
  'Roob LLC',
  'Simonis and Sons',
  'Bartoletti - Effertz',
  'Rath, Bayer and Rempel',
  'Grady, Renner and West',
  'Botsford, Dickinson and Schoen',
  'Lynch, Hills and Romaguera',
  'Hand, Gislason and Ritchie',
  'Marquardt - McClure',
  'Murray LLC',
  'Lowe, Kuhic and Buckridge',
];

const EMAILS = [
  'hedwig@mac.com',
  'jamuir@hotmail.com',
  'rddesign@yahoo.ca',
  'barnett@outlook.com',
  'maradine@yahoo.ca',
  'alhajj@sbcglobal.net',
  'world@gmail.com',
  'wildixon@me.com',
  'frikazoyd@hotmail.com',
  'rogerspl@msn.com',
  'skoch@msn.com',
  'dunstan@mac.com',
  'cvrcek@verizon.net',
  'penna@att.net',
  'osrin@mac.com',
];

const clientDocumentDiscretionSignStatuses = [
  DocumentDigitalStatus.READY_FOR_SIGNING,
  DocumentDigitalStatus.WAITING_FOR_SIGNATURE,
  DocumentDigitalStatus.SIGNATURE_RECEIVED,
];

const clientDocumentNonDiscretionSignStatuses = [
  DocumentDigitalStatus.MISSING_SIGNATURE,
  DocumentDigitalStatus.WAITING_FOR_SIGNATURE,
  DocumentDigitalStatus.SIGNATURE_RECEIVED,
];

const clientDocumentDiscretionPrintStatuses = [
  DocumentOriginalStatus.READY_FOR_PRINTING,
  DocumentOriginalStatus.WAITING_TO_BE_RECEIVED,
  DocumentOriginalStatus.RECEIVED,
];

const clientDocumentNonDiscretionPrintStatuses = [
  DocumentOriginalStatus.MISSING_DOCUMENT,
  DocumentOriginalStatus.WAITING_TO_BE_RECEIVED,
  DocumentOriginalStatus.RECEIVED,
];

const questionsMetadata: QuestionsMetadata = {
  total_count: 3,
  reviewed_dividend_event_report: false,
  accepted_disclaimer: false,
};

export class ClientManagementMockService {
  private wmClients: Client[] = [];
  private fmClients: Client[] = [];
  private wmClientInvites: ClientInvite[] = [];

  private readonly wmClientDocuments = new Map<string, DocumentTypesResponse>();
  private readonly fmClientDocuments = new Map<string, ClientDocumentsResponse>();
  private readonly boClientDocuments: ClientDocumentsResponse = this.createClientDocuments();
  private readonly investorClientDocuments: ClientDocumentsResponse = this.createInvestorClientDocuments();

  private readonly wmClientInviteStatusStore = new Map<string, ClientInviteStatusResponse>();

  // store data
  private readonly personalDetailsStore: Map<string, PersonalDetails> = new Map<string, PersonalDetails>();
  private readonly juristicDetailsStore: Map<string, JuristicDetails> = new Map<string, JuristicDetails>();
  private readonly trustDetailsStore: Map<string, TrustDetails> = new Map<string, TrustDetails>();
  private readonly taxDetailsStore: Map<string, TaxDetails> = new Map<string, TaxDetails>();
  private readonly fundDetailsStore: Map<string, FundDetails> = new Map<string, FundDetails>();
  private readonly bankingDetailsStore: Map<string, BankingDetails> = new Map<string, BankingDetails>();
  private readonly confirmedStore: Map<string, boolean> = new Map<string, boolean>();

  // refund analysis
  private refundAnalysisProgress: RefundAnalysisProgress = RefundAnalysisProgress.COMPLETED;

  constructor(private readonly attachmentsMockService: AttachmentsMockService) {
    const currency = randomElement(['USD', 'EUR', 'UAH']);
    // WM clients
    for (let i = 0; i < CLIENT_COUNT; i++) {
      const type = randomBool() ? ClientType.NATURAL_PERSON : randomBool() ? ClientType.JURISTIC_ENTITY : ClientType.TRUST;
      let name: string;
      if (type === ClientType.NATURAL_PERSON) {
        name = `${randomElement(FIRST_NAMES)} ${randomElement(LAST_NAMES)}`;
      } else {
        name = randomElement(BUSINESS_NAMES);
      }
      const claimsExpiring = randomBool();

      const client = {
        id: randomId(),
        client_code: randomId(),
        type,
        name,
        status: randomEnumValue(ClientStatus),
        claims_expiring: claimsExpiring,
        claims_expiring_at: claimsExpiring ? randomDate(new Date().toISOString().split('T')[0], '2021-01-31') : null,
        estimated_value: randomBool()
          ? undefined
          : {
              value: Math.floor(Math.random() * 100000) + 1,
              currency,
            },
      };

      this.wmClients.push(client);

      this.wmClientDocuments.set(client.id, this.createClientDocumentTypes(randomBool()));
    }
    this.wmClients = this.wmClients.sort((a, _) => (a.claims_expiring ? -1 : 1));

    this.wmClientInvites = this.wmClients.map((c: Client) => ({
      id: c.id,
      client_code: c.client_code,
      name: c.name,
      invite_status: randomEnumValue(ClientInviteStatus),
    }));

    this.wmClientInvites.forEach((invite) => {
      this.wmClientInviteStatusStore.set(invite.id, {
        status: invite.invite_status,
        email: invite.invite_status === ClientInviteStatus.MISSING_EMAIL ? null : randomElement(EMAILS),
      });
    });

    this.wmClientInvites = this.wmClientInvites.sort((a, b) => {
      const aOrder = this.mapInviteStatusToOrder(a.invite_status);
      const bOrder = this.mapInviteStatusToOrder(b.invite_status);

      if (aOrder < bOrder) {
        return -1;
      }

      if (aOrder > bOrder) {
        return 1;
      }

      return 0;
    });

    // FM clients
    for (let i = 0; i < CLIENT_COUNT; i++) {
      const claimsExpiring = randomBool();

      const client = {
        id: randomId(),
        client_code: randomId(),
        type: ClientType.FUND,
        name: `Fund #${i}`,
        status: randomEnumValue(ClientStatus),
        claims_expiring: claimsExpiring,
        claims_expiring_at: claimsExpiring ? randomDate(new Date().toISOString().split('T')[0], '2021-01-31') : null,
        estimated_value: randomBool()
          ? undefined
          : {
              value: Math.floor(Math.random() * 100000) + 1,
              currency,
            },
      };

      this.fmClients.push(client);

      this.fmClientDocuments.set(client.id, this.createClientDocuments());
    }
    this.fmClients = this.fmClients.sort((a, _) => (a.claims_expiring ? -1 : 1));

    // BO banking details
    this.bankingDetailsStore.set('-|-', {
      ...emptyBankingDetails(),
      account: {
        account_holder_name: `${randomElement(FIRST_NAMES)} ${randomElement(LAST_NAMES)}`,
        account_number: null,
        bank_name: 'HSBC',
        currency_code: randomElement(['USD', 'EUR', 'UAH']),
        swift_code: null,
      },
    });
  }

  public getSavedClientById(token: string, id: string): Client | undefined {
    if (token === 'WM') {
      return this.wmClients.find((client) => client.id === id);
    } else if (token === 'FM') {
      return this.fmClients.find((client) => client.id === id);
    }
    return undefined;
  }

  public getRandomSavedClientById(token: string): Client | undefined {
    if (token === 'WM') {
      return randomElement(this.wmClients);
    } else if (token === 'FM') {
      return randomElement(this.fmClients);
    }
    return undefined;
  }

  public getRefundAnalysisStatus(_: HttpRequest<any>): HttpResponse<RefundAnalysisStatus> {
    return new HttpResponse({ status: 200, body: { progress: this.refundAnalysisProgress } });
  }

  public submitClientDetails(_: HttpRequest<SubmitClientDetailsRequest>): HttpResponse<any> {
    this.refundAnalysisProgress = RefundAnalysisProgress.IN_PROGRESS;

    return new HttpResponse({ status: 200, body: null });
  }

  public getClientsMetadata(request: HttpRequest<any>): HttpResponse<ClientsMetadata> {
    const token = parseToken(request);
    const currencyCode = request.params.get('currency_code');

    let list: Client[];

    if (token.startsWith('WM')) {
      list = this.wmClients;
    } else if (token.startsWith('FM')) {
      list = this.fmClients;
    }

    const statusMap: Map<ClientStatus, number> = new Map<ClientStatus, number>();

    list.forEach((client) => {
      let statusCount = statusMap.get(client.status);
      if (!statusCount) {
        statusCount = 0;
      }
      statusCount++;
      statusMap.set(client.status, statusCount);
    });

    const validStatuses = Array.from(statusMap.keys());

    const clientsWithValues = list.filter((item) => !isNil(item.estimated_value));
    const metadata: ClientsMetadata = {
      total_count: list.length,
      status_counts: validStatuses.map((status) => ({
        status,
        count: statusMap.get(status),
      })),
      claims_expiring_count: list.filter((c) => c.claims_expiring).length,
      total_estimated_value:
        clientsWithValues.length === 0
          ? undefined
          : {
              value: clientsWithValues.reduce((sum, item) => sum + item.estimated_value.value, 0),
              currency: currencyCode,
            },
    };

    return new HttpResponse({ status: 200, body: metadata });
  }

  public getClients(request: HttpRequest<any>): HttpResponse<ClientBatch> {
    const token = parseToken(request);
    const limit = Number(request.params.get('limit'));
    const offset = Number(request.params.get('offset'));
    const currencyCode = request.params.get('currency_code');
    const name = request.params.get('name');
    const statuses = request.params.get('statuses');

    let list: Client[];

    if (token.startsWith('WM')) {
      list = this.wmClients;
    } else if (token.startsWith('FM')) {
      list = this.fmClients;
    }

    if (name) {
      list = list.filter((client) => {
        if (!client.name) {
          return false;
        }

        return client.name.includes(name);
      });
    }

    if (statuses) {
      const parsedStatuses = statuses.split(',') as ClientStatus[];

      list = list.filter((client) => parsedStatuses.includes(client.status));
    }

    const clientBatch: ClientBatch = {
      items: paginate(list, limit, offset).map((client) =>
        client.estimated_value
          ? {
              ...client,
              estimated_value: { ...client.estimated_value, currency: currencyCode },
            }
          : client
      ),
      total_count: list.length,
    };

    return new HttpResponse({ status: 200, body: clientBatch });
  }

  public getClientsForQuestions(request: HttpRequest<any>): HttpResponse<QuestionsClientsBatch> {
    const limit = Number(request.params.get('limit'));
    const offset = Number(request.params.get('offset'));

    const list = this.wmClients;

    const clientBatch: QuestionsClientsBatch = {
      items: paginate(list, limit, offset).map((client) => ({
        ...client,
        questions_total: Math.floor(Math.random() * 11),
        questions_answered: Math.floor(Math.random() * 5),
        status: ClientQuestionsSummaryStatus.IN_PROGRESS,
      })),
      total_count: list.length,
    };

    return new HttpResponse({ status: 200, body: clientBatch });
  }

  public getClientsInformationSheet(_: HttpRequest<any>): HttpResponse<Attachment> {
    return new HttpResponse({
      status: 200,
      body: {
        id: randomId(),
        file_name: 'client-information-sheet.pdf',
        file_size: 13264,
        mime_type: 'application/pdf',
        url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      },
    });
  }

  public getClientInvites(request: HttpRequest<any>): HttpResponse<ClientInviteBatch> {
    const token = parseToken(request);
    const limit = Number(request.params.get('limit'));
    const offset = Number(request.params.get('offset'));
    const name = request.params.get('name');
    const inviteStatuses = request.params.get('invite_statuses');

    let list: ClientInvite[];

    if (token.startsWith('WM')) {
      list = this.wmClientInvites;
    }

    if (name) {
      list = list.filter((client) => {
        if (!client.name) {
          return false;
        }

        return client.name.includes(name);
      });
    }

    if (inviteStatuses) {
      const parsedStatuses = inviteStatuses.split(',') as ClientInviteStatus[];

      list = list.filter((client) => parsedStatuses.includes(client.invite_status));
    }

    const clientInviteBatch: ClientInviteBatch = {
      items: paginate(list, limit, offset),
      total_count: list.length,
    };

    return new HttpResponse({ status: 200, body: clientInviteBatch });
  }

  public getClientInvitesMetadata(request: HttpRequest<any>): HttpResponse<ClientInvitesMetadata> {
    const token = parseToken(request);

    if (!token.startsWith('WM')) {
      return new HttpResponse({ status: 400 });
    }

    const list: ClientInvite[] = this.wmClientInvites;

    const statusMap: Map<ClientInviteStatus, number> = new Map<ClientInviteStatus, number>();

    list.forEach((client) => {
      let statusCount = statusMap.get(client.invite_status);
      if (!statusCount) {
        statusCount = 0;
      }
      statusCount++;
      statusMap.set(client.invite_status, statusCount);
    });

    const validStatuses = Array.from(statusMap.keys());

    const metadata: ClientInvitesMetadata = {
      total_count: list.length,
      status_counts: validStatuses.map((status) => ({
        status,
        count: statusMap.get(status),
      })),
    };

    return new HttpResponse({ status: 200, body: metadata });
  }

  public inviteClientsBulk(request: HttpRequest<BulkInviteClientsRequest>): HttpResponse<BulkInviteClientsResponse> {
    this.wmClientInvites.forEach((invite) => {
      if (!request.body.client_ids.includes(invite.id)) {
        return;
      }
      invite.invite_status = ClientInviteStatus.INVITED;
      this.wmClientInviteStatusStore.set(invite.id, {
        status: ClientInviteStatus.INVITED,
        email: this.wmClientInviteStatusStore.get(invite.id)?.email,
      });
    });
    return new HttpResponse({ status: 200, body: { success_client_ids: request.body.client_ids, failed_client_ids: [] } });
  }

  public inviteAllClients(_: HttpRequest<any>): HttpResponse<BulkInviteClientsResponse> {
    this.wmClientInvites
      .filter((invite) => invite.invite_status === ClientInviteStatus.READY_TO_INVITE)
      .forEach((invite) => {
        invite.invite_status = ClientInviteStatus.INVITED;
        this.wmClientInviteStatusStore.set(invite.id, {
          status: ClientInviteStatus.INVITED,
          email: this.wmClientInviteStatusStore.get(invite.id)?.email,
        });
      });
    return new HttpResponse({ status: 200, body: null });
  }

  public submitClientEmail(request: HttpRequest<SetClientEmailRequest>, id: string): HttpResponse<void> {
    const inviteStatusResponse = this.wmClientInviteStatusStore.get(id);
    if (!inviteStatusResponse) {
      return new HttpResponse({ status: 404 });
    }
    this.wmClientInviteStatusStore.set(id, { email: request.body.email, status: ClientInviteStatus.READY_TO_INVITE });
    return new HttpResponse({ status: 200 });
  }

  public getClientInviteStatus(_: HttpRequest<any>, id: string): HttpResponse<ClientInviteStatusResponse> {
    const inviteStatusResponse = this.wmClientInviteStatusStore.get(id);
    if (!inviteStatusResponse) {
      return new HttpResponse({ status: 404 });
    }
    return new HttpResponse({ status: 200, body: inviteStatusResponse });
  }

  public getClientDetails(request: HttpRequest<any>, id: string): HttpResponse<ClientDetails> {
    const token = parseToken(request);

    let list: Client[];

    if (token.startsWith('WM')) {
      list = this.wmClients;
    } else if (token.startsWith('FM')) {
      list = this.fmClients;
    }

    const client = list.find((cls) => cls.id === id);

    let clientDetails: ClientDetails;

    switch (client.type) {
      case ClientType.NATURAL_PERSON:
        const personalDetails = this.personalDetailsStore.get(id) || {
          ...emptyPersonalDetails(),
          name: { first_name: client.name.split(' ')[0], last_name: client.name.split(' ')[1] },
        };
        const naturalClientDetails: NaturalClientDetails = {
          type: ClientType.NATURAL_PERSON,
          personal_details: { ...personalDetails, date_of_birth_required: randomBool(), identification_number_required: randomBool() },
          tax_details: this.taxDetailsStore.get(id) || emptyTaxDetails(),
          confirmed: this.confirmedStore.get(id) || false,
        };

        clientDetails = naturalClientDetails;
        break;

      case ClientType.JURISTIC_ENTITY:
        const juristicDetails = this.juristicDetailsStore.get(id) || {
          ...emptyJuristicDetails(),
          name: client.name,
        };
        const juristicClientDetails: JuristicClientDetails = {
          type: ClientType.JURISTIC_ENTITY,
          juristic_details: {
            ...juristicDetails,
            date_of_incorporation_required: randomBool(),
            registration_number_required: randomBool(),
          },
          tax_details: this.taxDetailsStore.get(id) || emptyTaxDetails(),
          confirmed: this.confirmedStore.get(id) || false,
        };

        clientDetails = juristicClientDetails;
        break;

      case ClientType.TRUST:
        const trustJuristicDetails = this.juristicDetailsStore.get(id) || {
          ...emptyJuristicDetails(),
          name: client.name,
        };
        const trustClientDetails: TrustClientDetails = {
          type: ClientType.TRUST,
          juristic_details: {
            ...trustJuristicDetails,
            date_of_incorporation_required: randomBool(),
            registration_number_required: randomBool(),
          },
          trust_details: this.trustDetailsStore.get(id) || emptyTrustDetails(),
          tax_details: this.taxDetailsStore.get(id) || emptyTaxDetails(),
          confirmed: this.confirmedStore.get(id) || false,
        };

        clientDetails = trustClientDetails;
        break;

      case ClientType.FUND:
        const fundDetails = this.fundDetailsStore.get(id) || {
          ...emptyFundDetails(),
          name: client.name,
          type: 'Hedge Fund',
          registration_number_required: randomBool(),
          launch_date_required: randomBool(),
        };
        const fundClientDetails: FundClientDetails = {
          type: ClientType.FUND,
          fund_details: fundDetails,
          confirmed: this.confirmedStore.get(id) || false,
        };

        clientDetails = fundClientDetails;
        break;
    }

    return new HttpResponse({ status: 200, body: clientDetails });
  }

  public updateClientDetails(request: HttpRequest<ClientDetails>, id: string): HttpResponse<ClientDetails> {
    const token = parseToken(request);

    let list: Client[];

    if (token.startsWith('WM')) {
      list = this.wmClients;
    } else if (token.startsWith('FM')) {
      list = this.fmClients;
    }

    const client = list.find((cls) => cls.id === id);

    let clientDetails: ClientDetails;

    switch (client.type) {
      case ClientType.NATURAL_PERSON:
        const naturalClientDetails: NaturalClientDetails = request.body as NaturalClientDetails;

        this.personalDetailsStore.set(id, naturalClientDetails.personal_details);
        this.taxDetailsStore.set(id, naturalClientDetails.tax_details);

        clientDetails = naturalClientDetails;
        break;

      case ClientType.JURISTIC_ENTITY:
        const juristicClientDetails: JuristicClientDetails = request.body as JuristicClientDetails;

        clientDetails = request.body as JuristicClientDetails;

        this.juristicDetailsStore.set(id, juristicClientDetails.juristic_details);
        this.taxDetailsStore.set(id, juristicClientDetails.tax_details);

        clientDetails = juristicClientDetails;
        break;

      case ClientType.TRUST:
        const trustClientDetails: TrustClientDetails = request.body as TrustClientDetails;

        this.juristicDetailsStore.set(id, trustClientDetails.juristic_details);
        this.trustDetailsStore.set(id, trustClientDetails.trust_details);
        this.taxDetailsStore.set(id, trustClientDetails.tax_details);

        clientDetails = trustClientDetails;
        break;

      case ClientType.FUND:
        const fundClientDetails: FundClientDetails = request.body as FundClientDetails;

        this.fundDetailsStore.set(id, fundClientDetails.fund_details);

        clientDetails = fundClientDetails;
        break;
    }
    this.confirmedStore.set(id, request.body.confirmed);

    return new HttpResponse({ status: 200, body: clientDetails });
  }

  public getClientBankingDetails(_: HttpRequest<any>, id: string, currency: string): HttpResponse<BankingDetails> {
    return new HttpResponse({ status: 200, body: this.getBankingDetails(id, currency) || emptyBankingDetails() });
  }

  public updateClientBankingDetails(request: HttpRequest<BankingDetails>, id: string, currency: string): HttpResponse<BankingDetails> {
    this.setBankingDetails(id, currency, request.body);

    return new HttpResponse({ status: 200, body: request.body });
  }

  public getBankingDetails(id: string, currency: string): BankingDetails {
    return this.bankingDetailsStore.get(`${id}|${currency}`);
  }

  public setBankingDetails(id: string, currency: string, bankingDetails: BankingDetails): void {
    this.bankingDetailsStore.set(`${id}|${currency}`, bankingDetails);
  }

  public getClientDocuments(request: HttpRequest<any>, id: string): HttpResponse<ClientDocumentsResponse | DocumentTypesResponse> {
    const token = parseToken(request);

    let clientDocuments: ClientDocumentsResponse;

    if (token.startsWith('WM')) {
      clientDocuments = this.wmClientDocuments.get(id);
    } else if (token.startsWith('FM')) {
      clientDocuments = this.fmClientDocuments.get(id);
    } else if (token.startsWith('BO') && id === CURRENT_USER_CLIENT_ID) {
      clientDocuments = this.boClientDocuments;
    } else if (token.startsWith('INVESTOR') && id === CURRENT_USER_CLIENT_ID) {
      clientDocuments = this.investorClientDocuments;
    }

    if (!clientDocuments) {
      return new HttpResponse({ status: 404 });
    }

    return new HttpResponse({ status: 200, body: clientDocuments });
  }

  public getClientDocumentsMetadata(_: HttpRequest<any>, __: string): HttpResponse<ClientDocumentsMetadata> {
    return new HttpResponse({
      status: 200,
      body: {
        actionable_items_count: {
          digital: Math.floor(Math.random() * 10),
          original: Math.floor(Math.random() * 10),
          upload: Math.floor(Math.random() * 10),
        },
      },
    });
  }

  public getClientQuestionsMetadata(_: HttpRequest<any>, __: string): HttpResponse<QuestionsMetadata> {
    return new HttpResponse({
      status: 200,
      body: questionsMetadata,
    });
  }

  private createClientDocuments(hasDiscretion = true): ClientDocumentsResponse {
    return {
      digital: new Array(Math.floor(Math.random() * 10)).fill(null).map((_, index) => {
        const status = randomElement(hasDiscretion ? clientDocumentDiscretionSignStatuses : clientDocumentNonDiscretionSignStatuses);
        return {
          id: randomId(),
          name: `Proof of authority #${index}`,
          description: 'Description',
          status,
          attachments:
            status === DocumentDigitalStatus.SIGNATURE_RECEIVED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
          type: DocumentType.CLIENT,
        };
      }),
      original: new Array(Math.floor(Math.random() * 10)).fill(null).map((_, index) => {
        const status = randomElement(hasDiscretion ? clientDocumentDiscretionPrintStatuses : clientDocumentNonDiscretionPrintStatuses);
        return {
          id: randomId(),
          name: `Proof of authority #${index}`,
          description: 'Description',
          status,
          url: `https://downloaddocument.com/${index}`,
          attachments: status === DocumentOriginalStatus.RECEIVED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
        };
      }),
      upload: new Array(Math.floor(Math.random() * 10 + 5)).fill(null).map((_, index) => {
        const status = randomEnumValue(DocumentUploadStatus);
        let downloadable = randomBool();
        let type;
        if (index < 3) {
          type = DocumentType.CLIENT;
        } else if (index === 4) {
          type = DocumentType.CLAIM;
          downloadable = true;
        } else {
          type = randomEnumValue(DocumentType);
        }
        const haveUrl = randomBool();
        const name = randomUploadDocumentTypeName(type);
        const totalCount = Math.floor(Math.random() * 5) + 1;
        return {
          id: randomId(),
          name: `${name} #${index}`,
          example_documents: this.attachmentsMockService.createAndStoreRandomAttachments(),
          description: 'Description',
          status,
          type,
          attachments: status === DocumentUploadStatus.UPLOADED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
          downloadable,
          url: downloadable && haveUrl ? `https://downloaddocument.com/${index}` : null,
          total_count: totalCount,
          uploaded_count: status === DocumentUploadStatus.UPLOADED ? totalCount : Math.floor(Math.random() * totalCount),
        };
      }),
      original_third_party: new Array(Math.floor(Math.random() * 10)).fill(null).map((_, index) => {
        const status = randomElement(hasDiscretion ? clientDocumentDiscretionPrintStatuses : clientDocumentNonDiscretionPrintStatuses);
        return {
          id: randomId(),
          name: `Tax document #${index}`,
          description: 'Description',
          status,
          attachments: status === DocumentOriginalStatus.RECEIVED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
          url: `https://downloaddocument.com/Tax document #${index}`,
        };
      }),
    };
  }

  private createClientDocumentTypes(hasDiscretion = true): DocumentTypesResponse {
    return {
      digital: new Array(Math.floor(Math.random() * 10)).fill(null).map((_, index) => {
        const status = randomElement(hasDiscretion ? clientDocumentDiscretionSignStatuses : clientDocumentNonDiscretionSignStatuses);
        return {
          id: randomId(),
          name: `Proof of authority #${index}`,
          description: 'Description',
          status,
          attachments:
            status === DocumentDigitalStatus.SIGNATURE_RECEIVED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
          total_count: 0,
          signed_count: 0,
        };
      }),
      original: new Array(Math.floor(Math.random() * 10)).fill(null).map((_, index) => {
        const status = randomElement(hasDiscretion ? clientDocumentDiscretionPrintStatuses : clientDocumentNonDiscretionPrintStatuses);
        return {
          id: randomId(),
          name: `Proof of authority #${index}`,
          description: 'Description',
          status,
          url: `https://downloaddocument.com/${index}`,
          attachments: status === DocumentOriginalStatus.RECEIVED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
          total_count: 0,
          printed_count: 0,
        };
      }),
      upload: new Array(Math.floor(Math.random() * 10)).fill(null).map((_, index) => {
        const status = randomEnumValue(DocumentUploadStatus);
        const downloadable = randomBool();
        const type = randomElement(Object.values(DocumentType));
        return {
          id: randomId(),
          name: `${name} #${index}`,
          example_documents: this.attachmentsMockService.createAndStoreRandomAttachments(),
          description: 'Description',
          status,
          attachments: status === DocumentUploadStatus.UPLOADED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
          uploaded_count: 0,
          type,
          downloadable,
          url: downloadable && type === DocumentType.CLIENT ? `https://downloaddocument.com/${index}` : null,
        };
      }),
    };
  }

  private createInvestorClientDocuments(_ = true): ClientDocumentsResponse {
    return {
      digital: [],
      original: [],
      upload: new Array(Math.floor(Math.random() * 10)).fill(null).map((__, index) => {
        const status = randomEnumValue(DocumentUploadStatus);
        return {
          id: randomId(),
          name: `Proof of authority #${index}`,
          example_documents: this.attachmentsMockService.createAndStoreRandomAttachments(),
          description: 'Description',
          status,
          url: status === DocumentUploadStatus.MISSING ? `https://downloaddocument.com/Tax document #${index}` : undefined,
          attachments: status === DocumentUploadStatus.UPLOADED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
          type: DocumentType.CLIENT,
          downloadable: true,
        };
      }),
      original_third_party: [],
    };
  }

  private mapInviteStatusToOrder(inviteStatus: ClientInviteStatus): number {
    let order;

    switch (inviteStatus) {
      case ClientInviteStatus.READY_TO_INVITE:
        order = 1;
        break;

      case ClientInviteStatus.MISSING_EMAIL:
        order = 2;
        break;

      case ClientInviteStatus.INVITED:
        order = 3;
        break;

      case ClientInviteStatus.REGISTERED:
        order = 4;
        break;
    }

    return order;
  }
}
