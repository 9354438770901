<div class="container">
  <h1 class="title">{{ 'CUSTODIAN.CONTACT_US_PAGE.TITLE' | translate }}</h1>

  <app-guide-card [custodianColors]="true">
    <svg-icon *appGuideCardIcon key="reply24"></svg-icon>
    <div class="font-size-emphasized" *appGuideCardTitle>{{ 'CUSTODIAN.CONTACT_US_PAGE.GUIDE_BOX.TITLE' | translate }}</div>
    <div *appGuideCardDescription>
      <div class="text-regular-normal mb-3">
        {{ 'CUSTODIAN.CONTACT_US_PAGE.GUIDE_BOX.DESCRIPTION' | translate }}
      </div>
    </div>
  </app-guide-card>

  <div class="text-center">
    <div class="description font-size-increased" [innerHTML]="'CUSTODIAN.CONTACT_US_PAGE.DESCRIPTION' | translate"></div>
    <a href="mailto:custodiantaxproducts@wtax.co" class="button custodian align-items-center">
      {{ 'CUSTODIAN.CONTACT_US_PAGE.SEND_US_AN_EMAIL' | translate }}
    </a>
  </div>
</div>
