import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageType } from '@shared/modules/toast-message/toast-message-type.enum';
import { ToastMessageService } from '@shared/modules/toast-message/toast-message.service';

@Injectable({ providedIn: 'root' })
export class ErrorNotificationService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly toastMessageService: ToastMessageService
  ) {}

  /**
   * Shows toast notification about error, resolves a promise after the toast was closed
   *
   * @param errorTranslationKey - translation key to load error message for
   */
  public async showErrorNotification(errorTranslationKey: string): Promise<void> {
    const error = await this.translateService.get(errorTranslationKey).toPromise();
    const toastRef = this.toastMessageService.open(error, { type: ToastMessageType.Error });
    await toastRef.afterClosed$().toPromise();
  }

  /**
   * Notifies user about an error by a putting an error message into console
   * and showing a translated message on the UI
   * resolves a promise when message is closed
   *
   * @param error - object of the error that was thrown
   * @param errorTranslationKey - translation key to load error message for
   */
  public async notifyAboutError(error: any, toastMessageTranslationKey: string): Promise<void> {
    // eslint-disable-next-line no-console
    console.error(error);
    await this.showErrorNotification(toastMessageTranslationKey);
  }
}
