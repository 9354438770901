import { BankingDetails, FundDetails, JuristicDetails, PersonalDetails, TaxDetails, TrustDetails } from '@wtax/data-angular';
import { randomBool } from '../functions';

const EMPTY_PERSONAL_DETAILS: PersonalDetails = {
  name: { first_name: 'Ivan', last_name: 'Soroka' },
  has_changed_name: null,
  past_names: null,
  date_of_birth: null,
  email: null,
  physical_address: null,
  has_same_postal_address: null,
  postal_address: null,
  identification_number: null,
};

export const emptyPersonalDetails = (): PersonalDetails => JSON.parse(JSON.stringify(EMPTY_PERSONAL_DETAILS));

const EMPTY_JURISTIC_DETAILS: JuristicDetails = {
  name: 'Supercharge',
  has_changed_name: null,
  past_names: null,
  date_of_incorporation: null,
  registration_number: null,
  email: null,
  physical_address: null,
  has_same_postal_address: null,
  postal_address: null,
};

export const emptyJuristicDetails = (): JuristicDetails => JSON.parse(JSON.stringify(EMPTY_JURISTIC_DETAILS));

const EMPTY_TRUST_DETAILS: TrustDetails = {
  trustees: null,
  beneficiaries: null,
};

export const emptyTrustDetails = (): TrustDetails => JSON.parse(JSON.stringify(EMPTY_TRUST_DETAILS));

export const emptyTaxDetails = (): TaxDetails =>
  JSON.parse(
    JSON.stringify({
      current_residency: { residency: 'Hungary, Budapest', tax_number: null },
      requires_vat_number: randomBool(),
      vat_number: null,
      has_changed_residency: null,
      past_residencies: null,
    })
  );

const EMPTY_FUND_DETAILS: FundDetails = {
  name: null,
  type: null,
  date_of_launch: null,
  closed: null,
  date_of_closing: null,
  registration_address: null,
  postal_address: null,
  current_tax_residency: null,
  vat_number: null,
  registration_number: null,
  portfolio_details: [],
};

export const emptyFundDetails = (): FundDetails => JSON.parse(JSON.stringify(EMPTY_FUND_DETAILS));

const EMPTY_BANKING_DETAILS: BankingDetails = {
  account: null,
  needs_more_details: true,
  beneficiary: null,
  intermediary: null,
};

export const emptyBankingDetails = (): BankingDetails => JSON.parse(JSON.stringify(EMPTY_BANKING_DETAILS));
