import { mapNamePayload } from '@shared/helpers/map-name-payload.helper';
import { MessageContact as MessageContactRequest } from '@wtax/data-angular';
import { MessageContact } from '../interfaces/message-contact.interface';

export const mapMessageContactPayload = (contact: MessageContact): MessageContactRequest => ({
  id: contact.id,
  name: mapNamePayload(contact.name),
  role: contact.role,
  profile_image: contact.profileImage,
});
