export enum RouteSegment {
  Root = '',
  Dashboard = 'dashboard',
  Payments = 'payments',
  ClientManagement = 'client-management',
  FundManagement = 'fund-management',
  AccountSetup = 'account-setup',
  MyAccount = 'my-account',
  AuthCallback = 'auth/callback',
  YourTasks = 'your-tasks',
  Messages = 'messages',
  Reporting = 'reporting',
  Insights = 'insights',
  TaxFormPrePopulation = 'tax-form-pre-population',
  HealthChecksOnBeneficialOwners = 'health-checks-on-beneficial-owners',
  HealthChecksOnTaxRateTables = 'health-checks-on-tax-rate-tables',
  TaxReclaimAccrualChecks = 'tax-reclaim-accrual-checks',
  Custodian = 'custodian',
  QueryManagement = 'query-management',
  ContactUs = 'contact-us',
}
