import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { RouteData } from '@shared/enums/route-data.enum';
import { UserRole } from '@wtax/data-angular';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as fromRoot from '../store/reducers';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate, CanLoad {
  constructor(private readonly store$: Store<fromRoot.State>) {}

  public canLoad(route: Route): Observable<boolean> {
    return this.userHasRoles$(route.data[RouteData.AllowedRoles]);
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userHasRoles$(route.data[RouteData.AllowedRoles]);
  }

  private userHasRoles$(allowedRoles: UserRole[]): Observable<boolean> {
    return this.store$.pipe(
      select(fromRoot.selectUserProfile),
      first((user) => !isNil(user)),
      map((user) => allowedRoles.includes(user.role))
    );
  }
}
