import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createSelector } from '@ngrx/store';
import { SignatoryDetailsStatus } from '@wtax/data-angular';
import isEmpty from 'lodash/isEmpty';
import * as fromUserProfile from './user-profile.reducer';

export interface State {
  router: RouterReducerState<any>;
  [fromUserProfile.userProfileKey]: fromUserProfile.State;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State>>('Root reducers token', {
  factory: () => ({
    router: routerReducer,
    [fromUserProfile.userProfileKey]: fromUserProfile.reducer,
  }),
});

export const selectProfileState = (state: State) => state[fromUserProfile.userProfileKey];
export const selectUserProfile = createSelector(selectProfileState, (state) => state.userProfile);
export const selectUserRole = createSelector(selectUserProfile, (profile) => profile?.role);
export const selectUserDiscretionAuthorization = createSelector(selectUserProfile, (profile) => profile?.discretionAuthorization);
export const selectSignatoryDetailsStatus = createSelector(selectUserProfile, (profile) => profile?.signatoryDetailsStatus);
export const selectTotalEstimatedValue = createSelector(selectUserProfile, (profile) => profile?.totalEstimatedValue);
export const selectUploadedSignatoryDetails = createSelector(
  selectSignatoryDetailsStatus,
  (status) => status === SignatoryDetailsStatus.UPLOADED
);
export const selectUserProfileCompleteness = createSelector(selectUserProfile, (profile) => profile?.accountCompleteness);
export const selectUserProfileOfficeAddress = createSelector(selectUserProfile, (profile) => profile?.officeAddress);
export const selectUserRestrictedToAccountSetup = createSelector(selectUserProfile, (profile) => profile?.restrictedToAccountSetup);
export const selectUserDefaultCurrency = createSelector(selectUserProfile, (profile) => profile?.defaultCurrency);
export const selectUserName = createSelector(selectUserProfile, (profile) => {
  if (!isEmpty(profile.name.title)) {
    return `${profile.name.title} ${profile.name.lastName}`;
  }
  if (!isEmpty(profile.name.firstName)) {
    return `${profile.name.firstName} ${profile.name.lastName}`;
  }
  return profile.name.lastName;
});
