import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route } from '@angular/router';
import { Config, CONFIG_TOKEN } from 'wtax-config';

@Injectable({ providedIn: 'root' })
export class MvpRestrictedGuard implements CanActivate, CanLoad {
  constructor(@Inject(CONFIG_TOKEN) private readonly config: Config) {}

  public canLoad(route: Route): boolean {
    return !this.config.restrictedRoutes.includes(route.path);
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    return !this.config.restrictedRoutes.includes(route.routeConfig.path);
  }
}
