import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LoadingIndicatorComponent } from '../components/loading-indicator/loading-indicator.component';

@Injectable({ providedIn: 'root' })
export class LoadingIndicatorService {
  private readonly loadingCount$ = new BehaviorSubject<number>(0);
  private overlayRef: OverlayRef;

  constructor(private readonly overlay: Overlay) {
    this.isVisible$().subscribe((isVisible) => {
      if (isVisible) {
        return this.show();
      } else {
        this.hide();
      }
    });
  }

  private show(): void {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      // It's handled by BlockScrollService.
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      hasBackdrop: true,
      backdropClass: 'loading-indicator-backdrop',
    });
    this.overlayRef.attach(new ComponentPortal(LoadingIndicatorComponent));
  }

  private hide(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  public isVisible$(): Observable<boolean> {
    return this.loadingCount$.pipe(
      map((count) => count > 0),
      distinctUntilChanged()
    );
  }

  public open(): void {
    this.loadingCount$.next(this.loadingCount$.getValue() + 1);
  }

  public dispose(): void {
    this.loadingCount$.next(this.loadingCount$.getValue() - 1);
  }
}
