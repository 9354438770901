import { Injectable } from '@angular/core';
import { ErrorNotificationService } from '@core/services/error-notification.service';
import * as fromRoot from '@core/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Account, AccountDataService, UserRole } from '@wtax/data-angular';
import { of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo, take } from 'rxjs/operators';
import * as fromProfile from '@core/store/reducers';
import * as AccountSelectionActions from '../actions/account-selection.actions';

@Injectable()
export class AccountSelectionEffects {
  public enter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountSelectionActions.enter),
      map(() => AccountSelectionActions.loadAccounts())
    )
  );

  public loadAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountSelectionActions.loadAccounts),
      switchMapTo(
        this.profileStore$.select(fromProfile.selectUserProfile).pipe(
          take(1),
          switchMap((user) => {
            if (user.role === UserRole.WEALTH_MANAGER || user.role === UserRole.FUND_MANAGER) {
              return this.accountDataService
                .getClientsByCurrentContact()
                .pipe(map((response: Array<Account>) => AccountSelectionActions.loadAccountsSuccess({ accounts: response })));
            }
          })
        )
      ),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'COMMON.ERROR.FAILED_TO_LOAD_ACCOUNTS_FOR_CONTACT');
        return of(AccountSelectionActions.loadAccountsError({ error: error.message }));
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly profileStore$: Store<fromRoot.State>,
    private readonly accountDataService: AccountDataService,
    private readonly errorNotificationService: ErrorNotificationService
  ) {}
}
