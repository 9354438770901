import { createAction, props } from '@ngrx/store';
import { Profile } from '@shared/interfaces/profile.interface';
import { AccountCompleteness, SignatoryDetailsStatus } from '@wtax/data-angular';

export const storeUserProfile = createAction('[UserProfile] storeProfile', props<{ profile: Profile }>());
export const storeUserProfileCompleteness = createAction(
  '[UserProfile] storeProfileCompleteness',
  props<{ completeness: AccountCompleteness }>()
);
export const storeUserProfileSignatoryDetailsStatus = createAction(
  '[UserProfile] signatoryDetailsStatus',
  props<{ signatoryDetailsStatus: SignatoryDetailsStatus }>()
);
