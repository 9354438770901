import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import * as fromRoot from '@core/store/reducers';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { roleLandingMap } from '@shared/constants/role-landing.map';
import { RouteData } from '@shared/enums/route-data.enum';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { AccountCompleteness } from '@wtax/data-angular';
import { forkJoin, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Config, CONFIG_TOKEN } from '../../../wtax-config';

@Injectable({ providedIn: 'root' })
export class AccountCompletenessGuard implements CanActivate, CanActivateChild {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly store$: Store<fromRoot.State>,
    private readonly router: Router
  ) {}

  public canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canActivateRoute$(childRoute.data[RouteData.RequiredCompleteness]);
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canActivateRoute$(route.data[RouteData.RequiredCompleteness]);
  }

  private canActivateRoute$(requiredCompleteness: AccountCompleteness): Observable<boolean | UrlTree> {
    return forkJoin([
      this.store$.pipe(select(fromRoot.selectUserProfileCompleteness), first()),
      this.store$.pipe(select(fromRoot.selectUserRestrictedToAccountSetup), first()),
      this.store$.pipe(select(fromRoot.selectUserRole), first()),
    ]).pipe(
      map(([completeness, restrictedToAccountSetup, role]) => {
        if (environment.debug && this.config.skipAccountCompletenessCheck) {
          return true;
        }
        if (requiredCompleteness === AccountCompleteness.COMPLETED && (completeness !== requiredCompleteness || restrictedToAccountSetup)) {
          return this.router.createUrlTree([RouteSegment.Root, RouteSegment.AccountSetup]);
        }
        if (requiredCompleteness === AccountCompleteness.INCOMPLETE && completeness !== requiredCompleteness) {
          return this.router.createUrlTree(roleLandingMap.get(role) || []);
        }
        return true;
      })
    );
  }
}
