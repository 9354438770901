import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ExpansionPanelHeaderContentDirective } from './expansion-panel-header-content.directive';
import { ExpansionPanelComponent } from './expansion-panel.component';

@NgModule({
  declarations: [ExpansionPanelComponent, ExpansionPanelHeaderContentDirective],
  imports: [SharedModule],
  exports: [ExpansionPanelComponent, ExpansionPanelHeaderContentDirective],
})
export class ExpansionPanelModule {}
