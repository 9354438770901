import * as fromRoot from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { UserRole } from '@wtax/data-angular';

export const isCurrentUserCustodian = createSelector(fromRoot.selectUserRole, (role) => role === UserRole.CUSTODIAN);
export const isCurrentUserPrivateWealth = createSelector(fromRoot.selectUserRole, (role) => role === UserRole.WEALTH_MANAGER);
export const isCurrentUserInstitutions = createSelector(
  fromRoot.selectUserRole,
  (role) => role === UserRole.FUND_MANAGER || UserRole.INVESTOR || UserRole.BENEFICIAL_OWNER
);
